import './how-it-works.scss';

import { selectHowItWorksBlockState, onChangeHowItWorksState } from "./howItWorksSlice";
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { TOUCH_TYPE } from '../../config/config';
import { useEffect } from 'react';
import { selectBoxId } from '../home/homeSlice';
import { VERSION } from '../../version';

export interface HowItWorksProps {
    onChangeHowItWorksWrapperShow: () => void;
}

export function HowItWorks(props: HowItWorksProps) {
    const dispatch = useAppDispatch();
    let howItWorksBlockState = useAppSelector(selectHowItWorksBlockState);
    let index = howItWorksBlockState.indexOf(true)
    const onChange = (index:number)=>{
        dispatch(onChangeHowItWorksState(index))
      
    }
    useEffect(()=>{
        const element = document.getElementById(`section-${index}`);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    },[index])
    return (
        <section className="how-it-works_wrapper">
            <div onClick={props.onChangeHowItWorksWrapperShow} className="darked-background"></div>
            <div className="how-it-works">
                <span className="how-it-works-heading">Как это работает?</span>
                <div id='section-0' className={`how-it-works-block ${howItWorksBlockState[0] == false ? ' closed' : ''}`} onClick={() => onChange(0)}>
                    <span className="how-it-works-block-heading">Как оставить в ремонт?</span>
                    <div className="how-it-works-block-description">
                        <ul className="how-it-works-block-description-list">
                            <li className="how-it-works-block-description-list-line">Выберите условие ремонта (быстрее или дешевле)</li>
                            <li className="how-it-works-block-description-list-line">Выберите имеющиеся у телефона дефекты</li>
                            <li className="how-it-works-block-description-list-line">Выберите модель телефона</li>
                            <li className="how-it-works-block-description-list-line">Введите личные данные</li>
                            <li className="how-it-works-block-description-list-line">Поместите телефон в ячейку, следуя инструкции</li>
                            <li className="how-it-works-block-description-list-line">Заберите чек</li>
                        </ul>
                    </div>
                </div>
                <div id="section-1" className={`how-it-works-block ${howItWorksBlockState[1] == false ? ' closed' : ''}`} onClick={() => onChange(1)}>
                    <span className="how-it-works-block-heading">Где ремонтируем?</span>
                    <div className="how-it-works-block-description">
                        <article className="how-it-works-block-article">
                            Все заказы с наших терминалов ремонтируются непосредственно в центральной мастерской на проверенном профессиональном оборудовании.
                        </article>
                        <img src="assets/img/repair-1.jpg" alt="" className="how-it-works-block-img" />
                        <img src="assets/img/repair-2.jpg" alt="" className="how-it-works-block-img" />
                        <img src="assets/img/repair-3.jpg" alt="" className="how-it-works-block-img" />
                    </div>
                </div>
                <div id="section-2"  className={`how-it-works-block section-2 ${howItWorksBlockState[2] == false ? ' closed' : ''}`} onClick={() => onChange(2)}>
                    <span className="how-it-works-block-heading">Как перевозим?</span>
                    <div className="how-it-works-block-description">
                        <article className="how-it-works-block-article">
                            Наша курьерская служба в кратчайшие сроки заберет ваш любимый телефон в ремонт или доставит готовый непосредственно в терминал, сэкономив ваше время.
                        </article>
                        <img src="assets/img/bag.png" alt="" className="how-it-works-block-img" />
                    </div>
                </div>
                <div id="section-3"  className={`how-it-works-block section-3 ${howItWorksBlockState[3] == false ? ' closed' : ''}`} onClick={() => onChange(3)}>
                    <span className="how-it-works-block-heading">Доступная цена</span>
                    <div className="how-it-works-block-description">
                        <article className="how-it-works-block-article">
                            Наш Автономный Сервисный Центр предоставляет самые доступные цены, так как нет сотрудников на месте, которым нужно платить заработную плату, нет дорогой аренды и лишних затрат.
                            Также мы находимся в самых доступных локациях, работая без обедов и выходных.
                        </article>
                    </div>
                </div>
                <div id="section-4"  className={`how-it-works-block section-4 ${howItWorksBlockState[4] == false ? ' closed' : ''}`} onClick={() => onChange(4)}>
                    <span className="how-it-works-block-heading">Как забрать с ремонта?</span>
                    <div className="how-it-works-block-description">
                        <ul className="how-it-works-block-description-list">
                            <li className="how-it-works-block-description-list-line">Отсканируйте QR-код вашего заказа</li>
                            <li className="how-it-works-block-description-list-line">Оплатите согласованную сумму</li>
                            <li className="how-it-works-block-description-list-line">Заберите конверт с телефоном из ячейки</li>
                            <li className="how-it-works-block-description-list-line">Получите документацию о завершении ремонта</li>
                        </ul>
                    </div>
                </div>
                <div id="section-5" className={`how-it-works-block section-5 ${howItWorksBlockState[5] == false ? ' closed' : ''}`} onClick={() =>onChange(5)}>
                    <span className="how-it-works-block-heading">Как купить товар?</span>
                    <div className="how-it-works-block-description">
                        <ul className="how-it-works-block-description-list">
                            <li className="how-it-works-block-description-list-line">Выберите необходимую категорию</li>
                            <li className="how-it-works-block-description-list-line">Выберите товар</li>
                            <li className="how-it-works-block-description-list-line">Выберите подходящую цену</li>
                            <li className="how-it-works-block-description-list-line">Оплатите покупку</li>
                            <li className="how-it-works-block-description-list-line">Получите гарантию</li>
                            <li className="how-it-works-block-description-list-line">Заберите товар</li>
                        </ul>
                    </div>
                </div>
                <div id="section-6" className={`how-it-works-block section-6 ${howItWorksBlockState[6] == false ? ' closed' : ''}`} onClick={() => onChange(6)}>
                    <span className="how-it-works-block-heading">Гарантия безопасности</span>
                    <div className="how-it-works-block-description">
                        <ul className="how-it-works-block-description-list">
                            <li className="how-it-works-block-description-list-line">Наш терминал полностью сделан из железа</li>
                            <li className="how-it-works-block-description-list-line">Усиленные ячейки</li>
                            <li className="how-it-works-block-description-list-line">Все телефоны передаются только в ударопрочных конвертах</li>
                            <li className="how-it-works-block-description-list-line">Ведётся круглосуточное видеонаблюдение</li>
                            <li className="how-it-works-block-description-list-line">Все ремонтные работы и их качество фиксируются видеонаблюдением</li>
                        </ul>
                    </div>
                </div>
                <div id="section-7" className={`how-it-works-block section-7 ${howItWorksBlockState[7] == false ? ' closed' : ''}`} onClick={() => onChange(7)}>
                    <span className="how-it-works-block-heading">Наша команда</span>
                    <div className="how-it-works-block-description">
                        <img src="assets/img/master-1.jpeg" alt="" className="how-it-works-block-img" />
                        <span className="how-it-works-block-bold">Александр - главный инженер</span>
                        <article className="how-it-works-block-article">
                            Мастер со стажем 12 лет. <br />
                            Отремонтировал более 10000 устройств. Постоянный участник конференций по повышению квалификации. <br />
                            2 высших технических образования КНИТУ-КАИ им. Туполева.<br />
                        </article>
                        <img src="assets/img/master-2.jpg" alt="" className="how-it-works-block-img" />
                        <span className="how-it-works-block-bold">Тимур</span>
                        <article className="how-it-works-block-article">
                            Мастер по ремонту мобильной электроники с непрерывным стажем 3 года.<br />
                            В прошлом инженер слаботочных сетей с опытом более 3 лет.<br />
                            Высшее техническое образование КГЭУ.
                        </article>
                         {useSelector(selectBoxId)} {VERSION}
                    </div>
                </div>
            </div>
        </section>

    )
}
