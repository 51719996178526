import { useSelector } from 'react-redux';
import './timer-window.scss';

import { useAppDispatch } from '../../hooks';
import { closeWindow, openWindow, selectIsOpen, selectIsTimering,lastTouch, selectLastTouchDate, selectTimerValue, timeTick } from './timerWindowSlice';
// import { useHistory } from 'react-router-dom';
import userSmartHistory from "../../metrica/metrica";
import { useEffect } from 'react';

export interface TimerWindowProps {
    duration: number
    children: React.ReactNode
}

export function TimerWindow(props: TimerWindowProps) {

    let value = useSelector(selectTimerValue)
    let lastTouchValue = useSelector(selectLastTouchDate)
    let showTimer = useSelector(selectIsOpen)
    let isTimering = useSelector(selectIsTimering)
    let navigate = userSmartHistory();
    const dispatch = useAppDispatch()
    const onTouchScreen= async (e: React.TouchEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: timer touch screen")
        dispatch(lastTouch())
    }
    const onClickScreen = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: timer click screen")
        dispatch(lastTouch())
    }
    useEffect(() => {
        console.log(`DEBUG: timer start ${lastTouchValue}`)
        const interval = setTimeout(() => {
            console.log("DEBUG: timer stop")
            dispatch(openWindow())
        },props.duration);
        return () =>{
            clearInterval(interval)
            console.log("DEBUG: timer stop")
        };
    }, [lastTouchValue]);
    
    useEffect(() => {
        const interval = setTimeout(() => {
            dispatch(timeTick())
        }, 1000);

        return () => clearInterval(interval);
    }, [value]);
    if (value == 0 && showTimer) {
        dispatch(closeWindow())
        navigate.push('/')
    }
    return (
        <>
          <div  onClick={onClickScreen} >
            {props.children}
            </div>
            {showTimer ? (
                <section className={`card-methods_wrapper`}>
                    <div className="darked-background" ></div>
                    <div className="timer-window">
                        <span className="timer-title">Хотите продолжить?</span>
                        <div className="timer-value">
                            {value}
                        </div>
                        <div className="timer-buttons">
                            <div className="main-button black no" onClick={() => {
                                dispatch(closeWindow())
                                navigate.push('/')
                            }}>
                                НЕТ
                            </div>
                            <div className="main-button yellow yes"
                                onClick={() => {
                                    dispatch(closeWindow())
                                }}>ДА</div>
                        </div>
                    </div>
                </section>
            ) : (<></>)}

        </>
    )
}
