import { useAppDispatch, useAppSelector } from "../../hooks"

import {
    LoadingStatus,
    selectLoadingStatusState,
    selectOrderStatusState,
    selectPromptShowState,
    promptOpener,
    toggleSbpDialog,
    selectSecret,
    selectPaied,
    selectPrice,
    selectShowSbpState,
    toggleCardDialog,
    selectShowCardState,
    toggleShowLicence,
    selectShowLicenceState,
    selectIsWaitReceipt
} from "./orderStatusSlice"
import { host } from '../../network/host';

import moment from 'moment'
import { Loading } from "../../components/loading/Loading";
import { TopLine } from "../../components/top-line/TopLine";
import { useHistory } from "react-router"
import { CategoryLine } from "../../components/category-line/CategoryLine"
import './order-status.scss';
import { TOUCH_TYPE } from "../../config/config";
import { loadOrderStatus, taskAction } from "../../network/actions";
import { SbpWindow } from "../sbp-window/sbpWindow";
import { CardWindow } from "../card-window/cardWindow";
import { PayOption } from "../../components/pay-optoins/PayOptions";
import { LicenceWindow } from "../licence-window/licenceWindow";
import useSmartHistory from "../../metrica/metrica";
import { WaitReceipt } from "../../components/wait-receipt/WaitReceipt";


export function OrderStatus() {
    const orderLoadingStatus = useAppSelector(selectLoadingStatusState);
    const orderData = useAppSelector(selectOrderStatusState)

    const promptShow = useAppSelector(selectPromptShowState)
    const sbpShow = useAppSelector(selectShowSbpState)
    const waitReceipt = useAppSelector(selectIsWaitReceipt)
    const cardShow = useAppSelector(selectShowCardState)
    const showLicence = useAppSelector(selectShowLicenceState)
    const secret = useAppSelector(selectSecret)
    const paied = useAppSelector(selectPaied)
    const price = useAppSelector(selectPrice)
   
    const dispatch = useAppDispatch()
    const navigate = useSmartHistory()

    if (orderLoadingStatus == LoadingStatus.None || orderLoadingStatus == LoadingStatus.Loaded) {
        if (orderLoadingStatus == LoadingStatus.None) {
            dispatch({ type: "CLEANUP" })
            dispatch(loadOrderStatus(secret ?? ""))
        }
        return <Loading />
    }

    if (orderData == null || orderData.order == null) {
        return <Loading />
    }

    // const orderDate = moment(orderData.order.date).format("DD/MM/YYYY")
    //const warrantyDate = orderStatus.repair!=null? moment(orderStatus.repair.date).format("DD/MM/YYYY") : null
    const showLicenceHandler = () => {
        dispatch(toggleShowLicence())
    }
    const taskHander = (e: any) => {
        debugger
        if (secret != null && paied) {
         
            dispatch(taskAction(secret)).then(() => {
                navigate.push("/")
            })
        }

    }
    const repair = orderData.repair
    const statusText = orderData.order.status
    const status = orderData.order.order_status
    const repair_item = repair.repair_item
    
    const warranty_date = repair.warranty_date !=null ? moment(repair.warranty_date).format("DD.MM.YYYY") : null;
    const closed_date = repair.closed_date !=null ? moment(repair.closed_date).format("DD.MM.YYYY") : null;
    const takeEnable =(price==0|| paied)  && status == 9;
    debugger;
    return (<>
        <TopLine sectionStyle='right' sectionHeading='Статус заказа' sectionType='text' sectionText={orderData?.order.number} />

        {showLicence ? (
            <LicenceWindow onChangeVisible={() => { dispatch(toggleShowLicence()) }} />
        ) : null}
        {cardShow ? (
            <CardWindow onChangePaymentWindowShow={() => { dispatch(toggleCardDialog()) }} />
        ) : null}
        {sbpShow ? (
            <SbpWindow onChangePaymentWindowShow={() => { dispatch(toggleSbpDialog()) }} />
        ) : null}
        {waitReceipt ? (
            <WaitReceipt />
        ) : null}
        <div className="above-buttons-block">
        <section className="status-block ">


            <div className="status-columns">
                <CategoryLine categoryText={statusText} />

                <div className="status-block_half status_text">
                    <div className="status-block_half_line">
                        <span>{orderData.user.name} {orderData.user.last_name}</span>

                    </div>
                    <span className="status-block_half_link" onClick={showLicenceHandler} >Прочитать подтвержденный договор аферты</span>
                    <div className="status-block_half_line">
                        <b>Номер телефона:</b>
                        <span>{orderData.user.phone_number} </span>
                    </div>
                    {
                        orderData.phone.imei != null && orderData.phone.imei != '' ?
                            (<div className="status-block_half_line">
                                <b>IMEI:</b><span>{orderData.phone.imei}</span>
                            </div>) : (<></>)
                    }
                    {
                        orderData.phone.brand != null && orderData.phone.brand != '' ?
                            (<div className="status-block_half_line">
                                <b>Производитель:</b><span>{orderData.phone.brand}</span>
                            </div>) : (<></>)
                    }

                    {
                        orderData.phone.model != null && orderData.phone.model != '' ?
                            (<div className="status-block_half_line">
                                <b>Модель:</b><span>{orderData.phone.model}</span>
                            </div>) : (<></>)
                    }
                    <div className="repair_title">Выполненные работы</div>
                    {repair_item.map((a, i) => (
                        <div key={i} className="status-block_half_line">
                            <b>{a.name}:</b><span>{a.count} x {a.price}</span>
                        </div>
                    ))}
                    <div className="status-block_half_line">
                        <b>Сумма ремонта:</b><span>{price}</span>
                    </div>
                    <div className="status-block_half_line">
                        <b>Выдан:</b><span>{closed_date}</span>
                    </div>
                    <div className="status-block_half_line">
                        <b>Гарантия до:</b><span>{warranty_date}</span>
                    </div>
                </div>
            </div>
            <div className="status-block_half centered">


                {
                    !paied && price != 0 && status == 9 ?
                        (
                            <>
                                <div className="price-wrapper">
                                    <span className="prefix">К оплате:</span>
                                    <span className="status-block_half_price price">{price}₽</span>
                                </div>
                                <PayOption paySpb={() => {
                                    dispatch(toggleSbpDialog())
                                }} payCard={() => {
                                    dispatch(toggleCardDialog())
                                }} />
                            </>) : (<></>)
                }

            </div>

        </section>
        </div>
        <section className="status-block">
            <div className="status-block_half">
                {/* <span className="status-block_half_heading">Выполненные работы</span>
                <div className="status-block_half_line">
                    <b>Замена экрана:</b>
                    <span>8900 руб.</span>
                </div>
                <div className="status-block_half_line">
                    <b>Замена батареи:</b>
                    <span>960 руб.</span>
                </div>
                <div className="status-block_half_line">
                    <b>Сумма ремонта:</b><span>{orderStatus.orderPrice}</span>
                </div>
                <div className="status-block_half_line">
                    <b>Выдан:</b>
                    <span>{orderDate}</span>
                </div>
                <div className="status-block_half_line">
                    <b>Гарантия:</b>
                    <span>{warrantyDate}</span>
                </div>
                <span className="status-block_half_link">Обратиться по гарантии</span> */}
            </div>

        </section>
        <section className="status_buttons">
            <button className="main-button black back" onClick={() => { navigate.push('/') }}>Назад</button>
            <button className={`main-button yellow ${!takeEnable ? 'invisible' : null}`} onClick={taskHander}>Забрать телефон</button>
               
        </section>
    </>)

}
