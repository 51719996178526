import { HttpClient } from "sdk-remlite";
import { BaseService } from "../../share/rest/base.service";
import { Answer, EmptyAnswer } from "../../share/rest/model";
import { CheckConfirmCode, ConfirmResponse, SendSmsResponse, VerifyType } from "../model";



export class ConfirmService extends BaseService {
    constructor(core: HttpClient) {
        super(core)
    }
    public requestCode(phone: string, type: VerifyType): Promise<SendSmsResponse> {
        let path = `/api/v1/code/request`;
        return this._postWithoutWrapper<SendSmsResponse>(path, {
            phone,
            type_verification: type
        });
    }
    public verifyCode(data: CheckConfirmCode): Promise<ConfirmResponse> {
        let path = `/api/v1/code/verificate`;
        return this._postWithoutWrapper<ConfirmResponse>(path, data);
    }
}