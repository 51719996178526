import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"
import { ConfirmPhone } from "./ConfirmPhone";
import { confirmCodeCheck, smsRequest, phoneRequeste, loadConfig } from "../../network/actions";
import { needReload } from "../home/homeSlice";

export enum VerifyType {
  None = 0,
  Sms = 1,
  Call = 2,
  Optional = 3
}
export interface ConfirmPhoneState {
  focusInputKey: string;

  firstPhoneNumber: string;
  phoneEntered: boolean;
  confirmPhoneNumber: string;
  isConfirmCodeSended: boolean;
  isConfirmCodeEntered: boolean;
  isConfirmCodeSending: boolean;
  isVerificationCodeSending: boolean;
  confirmCode: string;
  isConfirmCodeValid: boolean
  isConfirmUserExists: boolean
  verifyPhoneType: VerifyType;
  selectedVerifyType: VerifyType;
}

const initialState: ConfirmPhoneState = {
  focusInputKey: "firstPhoneNumber",
  firstPhoneNumber: "+7(",
  phoneEntered: false,
  confirmPhoneNumber: "",
  confirmCode: "",
  isConfirmCodeValid: false,
  isConfirmUserExists: false,
  isConfirmCodeSended: false,
  isConfirmCodeSending: false,
  isVerificationCodeSending: false,
  isConfirmCodeEntered: false,
  verifyPhoneType: VerifyType.Optional,
  selectedVerifyType: VerifyType.None,

}
export const confirmPhoneSlice = createSlice({
  name: 'confirmPhone',
  initialState,
  reducers: {
    onKey: (state, action) => {
      switch (state.focusInputKey) {

        case 'firstPhoneNumber':

          if (state.firstPhoneNumber.length <= 15) {
            if (isNaN(action.payload) == false) {
              state.firstPhoneNumber += action.payload;
              if (state.firstPhoneNumber.length == 6) {
                state.firstPhoneNumber += ')';
              }
              if (state.firstPhoneNumber.length == 10 || state.firstPhoneNumber.length == 13) {
                state.firstPhoneNumber += '-';
              }
            }
          }
          state.phoneEntered = state.firstPhoneNumber.length == 16
          if (!state.phoneEntered) {

            state.confirmCode = ''
            state.isConfirmCodeEntered = false
            state.isConfirmCodeValid = false
            state.isConfirmUserExists = false
            state.isConfirmCodeSended = false
            state.isConfirmCodeSending = false
            state.isVerificationCodeSending = false
            state.isConfirmCodeEntered = false
          }

          break

        case 'confirmCode':
          if (state.confirmCode.length < 4 && isNaN(action.payload) == false) {
            state.confirmCode += action.payload;

          }
          state.isConfirmCodeEntered = state.confirmCode.length == 4
          break
        default:
          break
      };
    },
    onBackspace: (state) => {
      switch (state.focusInputKey) {

        case 'firstPhoneNumber':
          if (state.firstPhoneNumber.length >3){
            state.firstPhoneNumber = state.firstPhoneNumber.slice(0, -1);
            state.phoneEntered = state.firstPhoneNumber.length == 16
          }
        
          break
        case 'confirmCode':
          state.confirmCode = state.confirmCode.slice(0, -1);
          state.isConfirmCodeEntered = state.confirmCode.length == 4
          if (!state.isConfirmCodeEntered) {
            state.isVerificationCodeSending = false
          }
          break
      };
    },
    onChangeFocus: (state, action) => {
      state.focusInputKey = action.payload;
      if (action.payload == 'firstPhoneNumber') {
        if (state.firstPhoneNumber == "") {
          state.firstPhoneNumber = "+7(";
        }
      }
      if (action.payload == 'confirmCode') {

      }
    },

    selectedVerifyType: (state, action) => {
      state.selectedVerifyType = action.payload
    },
    onEnter: (state) => {
      switch (state.focusInputKey) {

        case 'firstPhoneNumber':
          // state.focusInputKey = "confirmCode"
          break
        case 'confirmCode':
          state.focusInputKey = ""
          break
      }
    },

  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {
      state.focusInputKey = "firstPhoneNumber"
      state.firstPhoneNumber = "+7("
      state.phoneEntered = false
      state.confirmPhoneNumber = ""
      state.confirmCode = ""
      state.selectedVerifyType = VerifyType.None
      state.isConfirmCodeValid = false
      state.isConfirmUserExists = false
      state.isConfirmCodeSended = false
      state.isConfirmCodeSending = false
      state.isVerificationCodeSending = false
      state.isConfirmCodeEntered = false
    })
    builder.addCase(smsRequest.pending, (state, action) => {
      state.isConfirmCodeSending = true
    })
    builder.addCase(confirmCodeCheck.pending, (state, action) => {
      state.isVerificationCodeSending = true
    })
    builder.addCase(phoneRequeste.pending, (state, action) => {
      state.isConfirmCodeSending = true
    })

    builder.addCase(loadConfig.fulfilled, (state, action) => {
      state.verifyPhoneType = action.payload.veryfication_type
    })
    builder.addCase(loadConfig.rejected, (state, action) => {
      
    })

    builder.addCase(phoneRequeste.fulfilled, (state, action) => {
      state.confirmPhoneNumber = state.firstPhoneNumber
      // state.isConfirmCodeSended = action.payload.success
      state.isConfirmCodeSended = true
      state.focusInputKey = 'confirmCode'
    })
    builder.addCase(smsRequest.fulfilled, (state, action) => {
      state.confirmPhoneNumber = state.firstPhoneNumber
      state.isConfirmCodeSended = true
      state.focusInputKey = 'confirmCode'
    })
    builder.addCase(confirmCodeCheck.fulfilled, (state, action) => {
      state.isConfirmCodeValid = action.payload.success
      state.isConfirmUserExists = action.payload.user_exists
    })
  }
})

export const { onKey, onBackspace, onChangeFocus, onEnter, selectedVerifyType } = confirmPhoneSlice.actions

export const selectShouldSend = (state: RootState) => {
  let isVerifyTypeOptional = state.confirmPhone.verifyPhoneType == VerifyType.Optional
  let isConfirmCodeDetermined = (
    !isVerifyTypeOptional ||
    state.confirmPhone.selectedVerifyType == VerifyType.Sms ||
    state.confirmPhone.selectedVerifyType == VerifyType.Call
  )
  let isShouldSend = state.confirmPhone.phoneEntered &&
    !state.confirmPhone.isConfirmCodeSended &&
    !state.confirmPhone.isConfirmCodeSending &&
    isConfirmCodeDetermined &&
    state.confirmPhone.verifyPhoneType != VerifyType.None
  return isShouldSend
}

export const selectShouldCheck = (state: RootState) => state.confirmPhone.phoneEntered &&
  state.confirmPhone.isConfirmCodeSended &&
  state.confirmPhone.isConfirmCodeEntered &&
  !state.confirmPhone.isVerificationCodeSending


export const selectConfirmPhoneState = (state: RootState) => state.confirmPhone
export const selectConfirmUserExists = (state: RootState) => state.confirmPhone.isConfirmUserExists
export const selectIsPhoneConfirmed = (state: RootState) => state.confirmPhone.isConfirmCodeValid
export const selectConfirmCodeValid = (state: RootState) => state.confirmPhone.isConfirmCodeValid

export const selectIsVerifyPhoneNumber = (state: RootState) => state.confirmPhone.verifyPhoneType != VerifyType.None

export const selectSelectedVerifyType = (state: RootState) => state.confirmPhone.selectedVerifyType
export const selectDeterminedVerifyType = (state: RootState) => {
  console.log(`DEBUG: selectedVerifyType ${state.confirmPhone.verifyPhoneType} ${state.confirmPhone.selectedVerifyType}`)
  if (state.confirmPhone.verifyPhoneType != VerifyType.Optional) {
    return state.confirmPhone.verifyPhoneType
  }else{
    return state.confirmPhone.selectedVerifyType
  }
 
}

export default confirmPhoneSlice.reducer
