import { useSelector } from 'react-redux';
import './saled-window.scss';
import { useAppDispatch } from '../../hooks';
import { closeWindow, lastTouch, selectExpireDate, selectIsOpen, selectPercent, selectPromoExpireDate, selectType } from './saledWindowSlice';
import userSmartHistory from "../../metrica/metrica";
import { useEffect } from 'react';
import { qrAction } from '../../store/qrmid';
import { selectDefaultQr, selectDefaultQrIndex, selectSelectPromoData } from '../home/homeSlice';

export interface SaledWindowProps {
    children: React.ReactNode
}

export function SaledWindow(props: SaledWindowProps) {
    let showSaled = useSelector(selectIsOpen)
    let percent = useSelector(selectPercent)
    let type = useSelector(selectType)
    let expireDate = useSelector(selectExpireDate)
    let promoExpireDate = useSelector(selectPromoExpireDate)
    let promoData = useSelector(selectSelectPromoData);
    let defaultQr = useSelector(selectDefaultQr);
    let defaultQrIndex = useSelector(selectDefaultQrIndex);
    let navigate = userSmartHistory();
    const dispatch = useAppDispatch()
    const onTouchScreen = async (e: React.TouchEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: timer touch screen")
        dispatch(lastTouch())
    }
    const onClickScreen = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        console.log("DEBUG: timer click screen")
        dispatch(lastTouch())
    }

    return (

        <>
            <div onClick={onClickScreen} >
                {props.children}
            </div>
            {showSaled ? type != 'promo' ? (
                <section className={`card-methods_wrapper`}>
                    <div className="darked-background" ></div>
                    <div className="saled-window">
                        <span className="saled-title">Вам предоставлена скидка</span>
                        <div className="sale-percent">{percent}%</div>

                        <div className="saled-buttons">
                            <div className="main-button yellow yes" onClick={() => {
                                dispatch(closeWindow())
                            }}>Далее</div>
                        </div>
                    </div>
                </section>
            ) : (
                <section className={`card-methods_wrapper`}>
                    <div className="darked-background" ></div>
                    <div className="saled-window">
                        <div className="promo_container">
                            <h1>АКЦИЯ </h1>
                            <h2>Бесплатный ремонт телефонов и скидки <br /> на все товары </h2>
                            <div className="features-list">
                                <ul>
                                    <li>Услуга по ремонту - бесплатна, оплата только за запчасть </li>
                                    <li>{promoData?.default_percent}% скидки на все товары в вендинге</li>
                                </ul>
                              
                            </div>
                           
                        </div>


                        <div className="saled-buttons">
                            {
                                <div className="main-button yellow yes" onClick={() => {
                                    if (promoData?.default_qr != null) {
                                        dispatch(qrAction(promoData.default_qr))
                                    }
                                    dispatch(closeWindow())
                                }}>ХОРОШО</div>
                            }
                            
                        </div>
                        <div className='expire_date'>
                                * акция действует до {promoExpireDate}
                                </div>
                    </div>
                </section>
            )

                : (<></>)}

        </>
    )
}
