import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, history } from './store/store';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import { Touches } from './Touches';
import { setQrScaner } from './tools';
import { locationChange } from './touchSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TouchEffect } from './components/touch-effect/TouchEffect';
import { QrScaner } from './components/qr-scaner/qr.scaner';
import { Splash } from './Splash';
import { VERSION } from './version';
import { SupportWindow } from './features/support-window/supportWindow';
import { EmulBox } from './components/emul-box/EmulBox';
// (window as any).qrData = function(){

//   history.push("/order-status")
// }

ReactDOM.render(
  <React.StrictMode>

    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Touches>
          <QrScaner>
            <TouchEffect>
                <SupportWindow>
                <App />
              {/* <Splash>
              
              </Splash> */}
              {/* <EmulBox/> */}
                </SupportWindow>
          
            </TouchEffect>
          </QrScaner>
        </Touches>

      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
document.title = `${VERSION}`
