import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import userSmartHistory from "../../metrica/metrica";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { TopLine } from '../../components/top-line/TopLine';
import './product-list.scss';
import { isSaled, selectCategories } from './productListSlice';
import { Product } from '../../sdk/client/model/BoxSdk/UIModel/product';
import { Loading } from '../../components/loading/Loading';

export function ProductList(props: any) {
    const dispatch = useAppDispatch();
    let path = window.location.pathname;
    let id = + path.split('/')[2];
    let navigate = userSmartHistory();
    let isSaledValue = useAppSelector(isSaled);
    let categories = useAppSelector(selectCategories);
    if (categories==null){
       return( <Loading/>)
    }
    let productClickHandler = (product: Product) => {
        dispatch({ type: "CLEANUP" })
        navigate.push(`/product/${product.id}/data`);
    }
    let category = categories.filter(a => a.id == id)[0]
    if (category==null){
        return( <Loading/>)
     }
    console.log(`Saled  ${isSaledValue}`)
    return (<>
        <TopLine onClickBack={() => { navigate.push('/') }} sectionStyle='left' sectionHeading='Категории' sectionType='icon' sectionIconName='back-button.svg' />
        
        <CategoryLine categoryText={category.name} />
        <section className="item-list product-item-list small">
            <div className="container">
                <div className={`item-cards`} >
                    {category.products.map((a: Product, i: number) => (
                        <div key={i} className={`item-card ${isSaledValue ? '' : 'salecard'}`}
                        
                        onClick={() => { productClickHandler(a) }}>
                            <span className="item-card_title">{a.name}</span>
                            <img src={a.main_img} alt="" className="item-card_pic" />

                            <div className="item-card_price">{a.price}₽
                                <div className="crossSale">
                                    <img className='crossSale' src="/assets/img/pricecross.svg" />
                                    <div className="item-card_deposit">
                                        {a.discount_price}₽
                                    </div>
                                </div>

                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </section>
    </>)
}
