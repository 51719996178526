import { useHistory } from "react-router-dom";
import { Keyboard } from "../../features/keyboard/Keyboard";
import { TopLine } from '../../components/top-line/TopLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import './client-data.scss';
import whatsapp from "../../assets/img/icons/whatsapp.svg";
import telegram from "../../assets/img/icons/telegram.svg";
import sms from "../../assets/img/icons/sms.svg";
import phone from "../../assets/img/icons/phone.svg";
import email from "../../assets/img/icons/email.svg";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    onBackspace,
    onChangeFocus,
    onKey,
    selectClientDataState,
    selectNecessaryClientDataFilled,
    onEnter,
    firstPromptOpener,
    selectFirstPromptShowState,
    secondPromptOpener,
    selectSecondPromptShowState,
    onChangePrefer,
    selectPreferState,
    selectIsBackToComment
} from "./clientDataSlice";

import { DownVideo } from "../../components/down-video/DownVideo";
import { CategoryLine } from "../../components/category-line/CategoryLine";
import { Checkbox } from "../../components/checkbox/Checkbox";
import { Prompt } from "../../components/prompt/Prompt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useSmartHistory from "../../metrica/metrica";


export function ClientData() {

    const clientData = useAppSelector(selectClientDataState);
    const clientDataFilled = useAppSelector(selectNecessaryClientDataFilled);
    const firstPromptShow = useAppSelector(selectFirstPromptShowState);
    const secondPromptShow = useAppSelector(selectSecondPromptShowState);
    let navigate = useSmartHistory()
    const dispatch = useAppDispatch()
    let selectedPrefer = useAppSelector(selectPreferState)
    const onChangePreferHandler = (e: any, prefer: string) => {
        dispatch(onChangePrefer(prefer));
    };
    const isPhoneValid = (phone: string) => {

        return phone.length == 16 || phone.length == 0
    }
    let secondPhoneError = !isPhoneValid(clientData.secondPhoneNumber)
    let continueHandler = () => {
        navigate.push('/phone-data');
    }
    let backHandler = () => {
        navigate.push('/confirm-phone')
    }
    let isCanContinue = clientData.firstName.length > 2
        && clientData.lastName.length > 2
    return (<>
        <OrderStages currentStage={1} />

        <div className="above-keyboard-block above-buttons-block">
            <CategoryLine categoryText='Введите данные' />
            <section className="input-client ">
                <div className="inputs">
                    <div className="order-input_line">
                        <div className="order-input_wrapper" onClick={() => dispatch(onChangeFocus('lastName'))}>
                            <input value={clientData.lastName} type="text"
                            
                                className={`order-input ${clientData.focusInputKey == 'lastName' ? 'focused' : ''}`}
                                onChange={() => { }}
                                placeholder={clientData.focusInputKey == "lastName" ? "" : "Фамилия"} />
                        </div>
                    </div>
                    <div className="order-input_line">
                        <div className="order-input_wrapper" onClick={() => dispatch(onChangeFocus('firstName'))}>
                            <input value={clientData.firstName} type="text" className={`order-input ${clientData.focusInputKey == 'firstName' ? 'focused' : ''}`}
                                onChange={() => { }}
                                placeholder={clientData.focusInputKey == "firstName" ? "" : "Имя"} />
                        </div>
                    </div>

                    <div className="order-input_line">
                        <div className="order-input_wrapper" onClick={() => dispatch(onChangeFocus('patronymic'))}>
                            <input value={clientData.patronymic} type="text"
                                onChange={() => { }}
                                className={`order-input ${clientData.focusInputKey == 'patronymic' ? 'focused' : ''}`}
                                placeholder={clientData.focusInputKey == "patronymic" ? "" : "Отчество"} />
                        </div>


                    </div>
                    
                    <div className="container call-prefer">
                        <span className="prefer_heading">Выберете способ связи:</span>
                        <div className="prefer_select">
                            {/* <button onClick={(e)=>onChangePreferHandler(e,'Viber')} className={`prefer_option ${selectedPrefer=='Viber'? ' active' : ''}`}>
                                <img src="/assets/img/icons/viber.png" alt="" className="option_pic" />
                            </button> */}
                            <button onClick={(e) => onChangePreferHandler(e, 'phonecall')} className={`prefer_option phonecall ${selectedPrefer.indexOf('phonecall') >= 0 ? ' active' : ''}`}>
                                <img src={phone} alt="" className="option_pic" />
                            </button>
                            <button onClick={(e) => onChangePreferHandler(e, 'Whatsapp')} className={`prefer_option whatsapp ${selectedPrefer.indexOf('Whatsapp') >= 0 ? ' active' : ''}`}>
                                <img src={whatsapp} alt="" className="option_pic" />
                            </button>
                            <button onClick={(e) => onChangePreferHandler(e, 'Telegram')} className={`prefer_option telegram ${selectedPrefer.indexOf('Telegram') >= 0 ? ' active' : ''}`}>
                                <img src={telegram} alt="" className="option_pic" />
                            </button>
                            <button onClick={(e) => onChangePreferHandler(e, 'SMS')} className={`prefer_option sms ${selectedPrefer.indexOf('SMS') >= 0 ? ' active' : ''}`}>
                                <img src={sms} alt="" className="option_pic" />
                            </button>
                           
                        </div>
                    </div>
                    <div className="order-input_line margin_top">
                        <div className="order-input_wrapper" onClick={() => dispatch(onChangeFocus('secondPhoneNumber'))}>
                            <input value={clientData.secondPhoneNumber}
                                type="text"
                                onChange={() => { }}
                                className={`order-input ${clientData.focusInputKey == 'secondPhoneNumber' ? 'focused' : secondPhoneError ? 'error' : ''}`} placeholder="Запасной номер" />
                            <Prompt promptOpener={() => dispatch(secondPromptOpener())} promptShow={secondPromptShow} promptHeading="Для чего нужна эта информация" promptDescription="Для связи с клиентом"></Prompt>
                        </div>
                    </div>
                </div>
                <div className="input-keyboard-column">
                    <div className="wrong title under_keyboard">
                        Неверно введена информация
                    </div>
                    
                    <div className="keyboard_wrapper">
                        <Keyboard onEnter={() => dispatch(onEnter())} continueDisabled={clientDataFilled ? false : true}
                            onBackspace={() => { dispatch(onBackspace()); }}
                            onKey={(a) => { dispatch(onKey(a)); }}
                            onClickContinue={() => { navigate.push('/phone-data'); }}
                            onClickBack={() => { }}
                        />
                    </div>
                  
                </div>

            </section>

        </div>
        <div className="defects_buttons">
            <button onClick={() => { backHandler() }} className="main-button black back">Назад</button>
            <button onClick={() => {
                if (isCanContinue) {
                    continueHandler()
                }
            }} className={`main-button yellow next ${(isCanContinue ? '' : 'disabled')}`} disabled={!isCanContinue}>Далее</button>
        </div>
        <DownVideo />
    </>)
}
