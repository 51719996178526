import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"
import { Brand, PhoneModel } from "./phones";
import { loadPhones } from "../../network/actions";
import { PhoneServerModel } from "../../sdk";

export interface PhoneDataState {
  focusInputKey: string;
  IMEI: string;
  dontKnowImei: boolean;
  dontKnowModel: boolean;
  dontKnowBrand: boolean;
  openImei:boolean;
  brandListShow: boolean;
  modelListShow: boolean;
  brands: Array<Brand>;
  phones: Array<PhoneModel>;
  selectedBrand: Brand|null;
  selectedModel: PhoneModel|null;
}

const initialState: PhoneDataState = {
  focusInputKey: "",
  IMEI: "",
  dontKnowImei: false,
  dontKnowModel: false,
  dontKnowBrand: false,
  brandListShow: false,
  modelListShow: false,
  openImei:false,
  brands: [],
  phones: [],
  selectedBrand:null,
  selectedModel:null,
}
export const phoneDataSlice = createSlice({
  name: 'phoneData',
  initialState,
  reducers: {
    swichImei: (state, action) => {
      state.openImei=!state.openImei;
    },
    onKey: (state, action) => {
      switch (state.focusInputKey) {
        case 'IMEI':
          if ((isNaN(action.payload) == false && state.IMEI.length <15)){
            state.IMEI += action.payload;
          }
          break
        // case 'phoneModel':
        //   if(state.phoneModel==""){
        //     state.phoneModel+=action.payload.toUpperCase();
        //   }
        //   else{
        //       state.phoneModel+=action.payload
        //   }
        //   break
        // case 'phoneBrand':
        //   if(state.phoneBrand==""){
        //     state.phoneBrand+=action.payload.toUpperCase();
        //   }
        //   else{
        //       state.phoneBrand+=action.payload
        //   }
        //   break
        default:
          state.focusInputKey = 'IMEI';
          if (isNaN(action.payload) == false){
            state.IMEI += action.payload;
          }       
          break;
      };
    },
    onBackspace: (state) => {
      switch (state.focusInputKey) {
        case 'IMEI':
          state.IMEI = state.IMEI.slice(0, -1);
          if(state.IMEI.length == 0){
            state.focusInputKey = '';
          }
          break
        //   case 'phoneBrand':
        //   state.phoneBrand = state.phoneBrand.slice(0, -1);
        //   if(state.phoneBrand.length == 0){
        //     state.focusInputKey = 'IMEI';
        //   }
        //   break
        // case 'phoneModel':
        //   state.phoneModel = state.phoneModel.slice(0, -1);
        //   if(state.phoneModel.length == 0){
        //     state.focusInputKey = 'phoneBrand';
        //   }
        //   break
        
      };
    },
    onEnter: (state) => {
      switch (state.focusInputKey) {
        case 'IMEI':
          state.focusInputKey = "phoneBrand"
          break
        case 'phoneBrand':
          state.focusInputKey = "phoneModel"
          break
        case 'phoneModel':
          state.focusInputKey = ""
          break
      }
    },
    onChangeFocus: (state, action) => {
      switch (action.payload) {
        case 'IMEI':
          if (!state.dontKnowImei) {
            state.focusInputKey = action.payload;
          }
          break
        case 'phoneModel':
          if (!state.dontKnowModel) {
            state.focusInputKey = action.payload;
          }
          break
        case 'phoneBrand':
          if(!state.dontKnowBrand){
            state.focusInputKey = action.payload;
          }
          break
      };

    },
    dontKnowImeiSwitch: (state) => {
      state.dontKnowImei = !state.dontKnowImei;
      if (state.dontKnowImei == true && state.focusInputKey == 'IMEI') {
        state.focusInputKey = 'phoneModel';
      }
    },
    dontKnowModelSwitch: (state) => {
      state.dontKnowModel = !state.dontKnowModel;
      if (state.dontKnowModel == true && state.focusInputKey == 'phoneModel') {
        state.focusInputKey = 'phoneBrand';
      }
      if(state.dontKnowBrand==true){
        state.dontKnowModel=true;
      }
      state.selectedModel=null;
    },
    dontKnowBrandSwitch: (state) => {
      state.dontKnowBrand = !state.dontKnowBrand;
      state.dontKnowModel = state.dontKnowBrand;
      if (state.dontKnowBrand == true && state.focusInputKey == 'phoneBrand') {
        state.focusInputKey = '';
      }
      state.selectedBrand=null;
    },
    selectBrand:(state,action:PayloadAction<number>)=>{
      let brandId = action.payload;
      state.brands = state.brands.map(a=>{
        a.selected = a.id == brandId
        return a
      })
      state.selectedBrand = state.brands.find(a=>a.selected)??null;
      state.selectedModel = null;
    },
    selectModel:(state,action:PayloadAction<number>)=>{
      let modelId = action.payload;
      state.phones = state.phones.map(a=>{
        a.selected  = a.id == modelId
        return a
      })
      state.selectedModel = state.phones.find(a=>a.selected)??null;
    },
    brandListOpener:(state)=>{
      state.brandListShow=!state.brandListShow
    },
    modelListOpener:(state)=>{
      state.modelListShow=!state.modelListShow
    }
  },
  extraReducers:(builder)=>{
    builder.addCase("CLEANUP",(state)=>{
      state.focusInputKey= "";
      state.IMEI= ""
      state.selectedBrand=null
      state.selectedModel=null
      state.dontKnowImei= false
      state.dontKnowModel= false
      state.dontKnowBrand=false
    })
    builder.addCase(loadPhones.fulfilled, (state, action) => {
      state.brands = action.payload.map(a=><Brand>{
          id:a.id,
          name:a.name,
          image: a.image,
      })
      
      let phones:Array<PhoneModel> =[];
      for (let brand of action.payload) {
        for (const p of brand.phone_model) {
          phones.push(<PhoneModel>{
            id: p.id,
            brand_id: brand.id,
            name: p.name,
            img: p.image,
            selected:false,
          })
        }
      }
      state.phones=phones
    })
  }
})
function selectMany<TIn, TOut>(input: TIn[], selectListFn: (t: TIn) => TOut[]): TOut[] {
  return input.reduce((out, inx) => {
    out.push(...selectListFn(inx));
    return out;
  }, new Array<TOut>());
}
export const {
  onKey,
  onBackspace,
  dontKnowImeiSwitch,
  dontKnowBrandSwitch,
  dontKnowModelSwitch,
  onChangeFocus,
  onEnter,
  brandListOpener,
  modelListOpener,
  selectBrand,
  selectModel,
  swichImei,
}
  = phoneDataSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectPhoneDataState = (state: RootState) => state.phoneData
export const selectBrandListShow = (state: RootState) => state.phoneData.brandListShow
export const selectModelListShow = (state: RootState) => state.phoneData.modelListShow
export const selectBrands = (state: RootState) => state.phoneData.brands
export const selectSelectedModel= (state: RootState) => state.phoneData.selectedModel
export const selectSelectedBranch= (state: RootState) => state.phoneData.selectedBrand
export const selectDontKnowImei = (state: RootState) => state.phoneData.dontKnowImei
export const selectDontKnowBrand = (state: RootState) => state.phoneData.dontKnowBrand
export const selectDontKnowModel = (state: RootState) => state.phoneData.dontKnowModel
export const selectOpenImei = (state: RootState) => state.phoneData.openImei
export const selectPhones = (state: RootState) =>{
  let phoneData= state.phoneData;
  let selectedBrand = phoneData.brands.find(a=>a.selected) ?? phoneData.brands[0]
  
  return  phoneData.phones.filter(a=>a.brand_id==selectedBrand.id).sort((a,b)=>a.name.localeCompare(b.name))

}
export const selectImeiText = (state: RootState) => state.phoneData.dontKnowImei ? 'Не знаю' : state.phoneData.IMEI
export const selectModelText = (state: RootState) => state.phoneData.dontKnowModel ? 'Не знаю' : state.phoneData.selectedModel?.name ?? null
export const selectBrandText = (state: RootState) => state.phoneData.dontKnowBrand ? 'Не знаю' : state.phoneData?.selectedBrand?.name ?? null;
export const selectNecessaryPhoneDataFilled = (state: RootState) => {
  let phoneData = state.phoneData;
  let validIMEI = phoneData.IMEI.length == 16 || phoneData.dontKnowImei == true;
  let validModel = phoneData.selectedModel != undefined || phoneData.dontKnowModel == true;
  let validBrand = phoneData.selectedBrand != undefined || phoneData.dontKnowBrand == true;
  if (validIMEI && validModel && validBrand ) {
    return true;
  } else {
    return false;
  }
}

export default phoneDataSlice.reducer
