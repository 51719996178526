import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"

export interface OrderDefectState {
    id: number,
    name: string,
    selected: boolean,
    color: 'yellow' | null
}

export interface OrderDefectsState {
    defectsList: OrderDefectState[]
}

const initialState: OrderDefectsState = {
    defectsList: [
        { id: 1, name: 'Попадание влаги', selected: false, color: null },
        { id: 2, name: 'Разбит экран', selected: false, color: null },
        { id: 3, name: 'После удара', selected: false, color: null },
        { id: 4, name: 'Нет изображения', selected: false, color: null },
        { id: 5, name: 'Не включается', selected: false, color: null },
        { id: 6, name: 'Проблемы со звуком', selected: false, color: null },
        { id: 7, name: 'Не заряжается', selected: false, color: null },
        { id: 8, name: 'После другого ремонта', selected: false, color: null },
        { id: 9, name: 'Не держит заряд', selected: false, color: null },
        { id: 10, name: 'Проблема с камерой', selected: false, color: null },
        { id: 11, name: 'Не работает сенсор', selected: false, color: null },
        { id: 12, name: 'Проблема с кнопкой', selected: false, color: null },
        { id: 13, name: 'Не ловит сеть', selected: false, color: null },
        { id: 14, name: 'Оставить комментарий', selected: false, color: 'yellow' },
    ]
}
export const orderDefectsSlice = createSlice({
    name: 'orderDefects',
    initialState,
    reducers: {
        toggleDefect: (state, action: PayloadAction<number>) => {
            for (let i = 0; i < state.defectsList.length; i++) {
                let item = state.defectsList[i];
                if (item.id == action.payload) {
                    item.selected = !item.selected;
                }
            }

        }
    },
    extraReducers: (builder) => {
        builder.addCase("CLEANUP", (state) => {
            for (let index = 0; index < state.defectsList.length; index++) {
                const element = state.defectsList[index];
                element.selected = false;
            }
        })
    }
})

export const { toggleDefect } = orderDefectsSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectOrderDefects = (state: RootState) => state.orderDefects

export const selectIsCanContinue = (state: RootState) => {
    for (let i = 0; i < state.orderDefects.defectsList.length; i++) {
        let item = state.orderDefects.defectsList[i];
        if (item.selected) {
            return true;
        }
    }
    return false;

}

export const selectIsNeedComment = (state: RootState) => {
    for (let i = 0; i < state.orderDefects.defectsList.length; i++) {
        let item = state.orderDefects.defectsList[i];
        if (item.id == 14) {
            return item.selected;
        }
    }
    return false;

}

export default orderDefectsSlice.reducer
