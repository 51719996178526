// import { Metrika } from "typescript-yandex-metrica"
// conts metrika = new Metrica(0000000);
import { useHistory } from 'react-router-dom';
import * as H from 'history';
export enum Pages{

}
const clentId = 92371148
export class SmartRouter {
    constructor(private  history :H.History<H.LocationState>){

    }
    goBack(){
        this.history.goBack()
    }
    push(path:string ){
        this.history.push(path)
        this.newPage(path)
    }
    newPage(ev:string){
       let w=  (window as any)
       let url = document.location
        w.ym(clentId,'hit',{
            url
        })
   //    debugger;
        // let ym = window["ym"] as any;
        // ym(clentId, 'hit', ev)
       // https://yandex.com/support/metrica/code/counter-spa-setup.html
    }
}


//npm install react-ym

export default function useSmartHistory(){
    let history = useHistory() 
    return new SmartRouter(history)
}
