import { BaseService } from '../../share/rest/base.service';
import { HttpClient } from '../../share/rest/http.client';
export class TouchService extends BaseService
{
constructor(core:HttpClient){
	super(core)
}
	public touchStart():Promise<void>{
		let path = `/api/v1/touch/start`;
		return this._post<void>(path);
	}
	public touchStop():Promise<void>{
		let path = `/api/v1/touch/stop`;
		return this._post<void>(path);
	}
}
