import { QrEvent } from '../../client/model/RemLite/Model/qr_event';
import { BaseService } from '../../share/rest/base.service';
import { HttpClient } from '../../share/rest/http.client';
export class QrService extends BaseService
{
constructor(core:HttpClient){
	super(core)
}
	public qrHandler(ev:QrEvent):Promise<void>{
		let path = `/api/v1/qr`;
		return this._post<void>(path,ev);
	}
}
