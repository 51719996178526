import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import './style/style.scss';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { history } from './store/store';
import { OrderStatus } from './features/order-status/OrderStatus';
import { OrderDefects } from './features/order-defects/OrderDefects';
import { Home } from './features/home/Home';
import { ClientData } from './features/client-data/ClientData';
import { RepairOptions } from './features/repair-options/RepairOptions';
import { ProductPage } from './features/product-page/ProductPage';
import { ProductList } from './features/product-list/ProductList';
import { OrderComment } from './features/order-comment/OrderComment';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { HeaderStories } from './components/stories/HeaderStories';
import { OrderEnd } from './features/order-end/OrderEnd';
import { PhoneData } from './features/phone-data/PhoneData';
import { QrScan } from './features/qr-scan/QrScan';
import { Finish } from './features/finish/Finish';
import { FinishCards } from './features/finish-cards/FinishCards';
import { FinishEnvelopeTake } from './features/finish-envelope-take/FinishEnvelopeTake';
import { FinishEnvelopePut } from './features/finish-envelope-put/FinishEnvelopePut';
import { IeData } from './features/ie-data/IeData';
import { CategoryLoadStatus, selectCategoriesLoadStatus, selectCategoriyVersion, selectIsNeedReload } from './features/home/homeSlice';
import { loadCategories, loadConfig, loadPhones, simProduct } from './network/actions';
import { useAppDispatch, useAppSelector } from "./hooks";
import { TimerWindow } from './features/timer-window/timerWindow';
import { FinishOrder } from './features/finish-order/FinishOrder';
import { FinishBuy } from './features/finish-buy/FinishBuy';
import { Splash } from './Splash';
import { SaledWindow } from './features/saled-window/saledWindow';
import { TechnicalCheck } from './features/technical-check/TechnicalCheck';
import { ConfirmPhone } from './features/confirm-phone/ConfirmPhone';
import { EmulBox } from './components/emul-box/EmulBox';
import { Stories } from './Stories';

function App() {
  const needReload = useAppSelector(selectIsNeedReload)
  const version = useAppSelector(selectCategoriyVersion)
  const dispatch = useAppDispatch();
  useEffect(() => {

    if (needReload) {
      dispatch(loadConfig())
      dispatch(loadCategories())
      dispatch(simProduct())
      dispatch(loadPhones())
    }
  }, [version])
  // <div className="App AppMini">
  return (
    <Splash>
      <Stories>
        <div className="App">
          <header className="App-header">
          </header>
          <div className="main-layout">

            <Router history={history}>
              {/* <Header /> */}
              <HeaderStories />
            </Router>
            <Router history={history}>
              <Switch>
                <Route path="/" exact render={() => (
                  <SaledWindow>
                    <Home />
                  </SaledWindow>
                )
                } />
                <Route path="/order-defects" exact render={() => (
                  <TimerWindow duration={60 * 1000}>
                    <OrderDefects />
                  </TimerWindow>
                )} />
                <Route path="/order-status" exact render={() =>
                  <OrderStatus />
                } />
                <Route path="/order-comment" exact render={() =>
                  <TimerWindow duration={60 * 1000}>
                    <OrderComment />
                  </TimerWindow>
                } />
                <Route path="/order-end" exact render={() =>
                  <TimerWindow duration={60 * 1000}>
                    <OrderEnd />
                  </TimerWindow>
                } />
                <Route path="/client-data" exact render={() =>
                  <TimerWindow duration={60 * 1000}>
                    <ClientData />
                  </TimerWindow>
                } />
                <Route path="/confirm-phone" exact render={() =>
                  <TimerWindow duration={60 * 1000}>
                    <ConfirmPhone />
                  </TimerWindow>
                } />
                <Route path="/phone-data" exact render={() =>
                  <TimerWindow duration={60 * 1000}>
                    <PhoneData />
                  </TimerWindow>


                } />
                <Route path="/repair-options" exact render={() =>
                  <TimerWindow duration={60 * 1000}>
                    <RepairOptions />
                  </TimerWindow>

                } />
                <Route path="/product/:id/data" exact render={() =>
                  <SaledWindow>
                    <ProductPage />
                  </SaledWindow>
                } />
                <Route path="/category/:id/products" exact render={() =>
                  <SaledWindow>
                    <ProductList />
                  </SaledWindow>
                } />
                <Route path="/qr-scan" exact render={() =>
                  <TimerWindow duration={60 * 1000}>
                    <QrScan />
                  </TimerWindow>
                } />
                <Route path="/finish" exact render={() => <Finish />} />
                <Route path="/finish-buy" exact render={() => <FinishBuy />} />
                <Route path="/finish-order" exact render={() => <FinishOrder />} />
                <Route path="/finish-cards" exact render={() => <FinishCards />} />
                <Route path="/finish-envelope-take" exact render={() => <FinishEnvelopeTake />} />
                <Route path="/finish-envelope-put" exact render={() => <FinishEnvelopePut />} />
                <Route path="/ie-data" exact render={() => <IeData />} />
                <Route path="/debug" exact render={() => <TechnicalCheck />} />
                <Route path="/emul" exact render={() => <EmulBox />} />
                <Redirect to='/' />
              </Switch>
            </Router>


            <Router history={history}>
              <Footer />
            </Router>
          </div>
        </div>
      </Stories>
    </Splash>
  );
}

export default App;


