import './category-line.scss';


export interface CategoryLineProps {
    categoryText: string;
}

export function CategoryLine(props: CategoryLineProps) {
    return (<section className="category_line">
    <div className="container">
        <span className="category_line_text">{props.categoryText}</span>
    </div>
</section>)
}
