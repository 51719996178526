import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
import { networkLayer } from "./client"
import { CheckConfirmCode, Factory } from "../sdk"
import {FullLiteOrderModel, VerifyType} from "../sdk/client/model"
// import {  } from "."

export interface  SimProduct{
  productId: number,
  inStock: boolean
  }
export const buyProduct = createAsyncThunk(
    'app/buyProduct',
    async (productId:number):Promise<void> => {
       return await networkLayer.productService.payProduct(productId)
    }
)

export const createOrder = createAsyncThunk(
    'app/createOrder',
    async (order:FullLiteOrderModel) => {
        await networkLayer.orderService.createOrder(order)
        return true
    }
)
export const taskAction = createAsyncThunk(
    'app/taskAction',
    async (secret:string) => {
       return await networkLayer.orderService.takeOrder(secret)
    }
)
export const onQrScaned = createAsyncThunk(
    'app/qr',
    async (qr:string) => {
       return await networkLayer.qrService.qrHandler( {qr:qr} )
    }
)
export const loadCategories = createAsyncThunk(
    'categories',
    async (thunkAPI) => {
      let list =  await networkLayer.productService.getCategories();
      return list
    })
export const loadConfig = createAsyncThunk(
    'config',
    async (thunkAPI) => {
      let list =  await networkLayer.configService.getConfig();
      return list
    })
export const simProduct = createAsyncThunk(
    'sim/product',
    async (thunkAPI) => {
      let simProduct =  await networkLayer.productService.getSimProduct();
      console.log(simProduct)
      
     
      return simProduct
    })
    
export const loadPhones = createAsyncThunk(
    'phones',
    async (thunkAPI) => {
      let list =  await networkLayer.productService.getPhones();
      return <Array<Factory>>list.items
    })
    
export const nfcPayProduct = createAsyncThunk(
    'nfcpay/product',
    async (productId:number) => {
      return await networkLayer.productService.payProduct(productId)
    })
    
export const nfcPayOrder = createAsyncThunk(
    'nfcpay/order',
    async (secret:string) => {
      return await networkLayer.orderService.payOrderNfc(secret)
    })
export const spbClose = createAsyncThunk(
    'spb/close',
    async () => {
      return await networkLayer.orderService.spbDialogClose()
    })
    
export const productQrPayText = createAsyncThunk(
    'product/qrpay',
    async (productId:number) => {
      let item =  await networkLayer.productService.payProductQr(productId)
      return {
        product_id: productId,
        qr_text:item.order_form_url
      }
    })
export const orderQrPayText = createAsyncThunk(
    'order/qrpay',
    async (secret:string) => {
      let item =  await networkLayer.orderService.payOrderQr(secret)
      
      return {
        secret: secret,
        qr_text:item.order_form_url
      }
    })
    
export const checkProductPayStatus = createAsyncThunk(
    'paycheckstatus',
    async (productId:number) => {
      await networkLayer.productService.buyQrStatus(productId)
    
    })
export const checkOrderPayStatus = createAsyncThunk(
    'order/check/pay/status',
    async (secret:string) => {
      await networkLayer.orderService.payOrderQRStatus(secret)
    
    })
export const loadOrderStatus = createAsyncThunk(
    'orderstatus/load',
    async (secret:string) => {
      var response =  await networkLayer.orderService.orderStatus(secret)
      return response
    })
  
export const buyPaymentFailed = createAction(
      'buy/failed',
  )
    
export const orderPaymentFailed = createAction(
    'orderpay/failed',
)
    
export const orderPaymentSuccess = createAction(
    'orderpay/success',
)
    
export const smsRequest = createAsyncThunk(
  'confirm/sms',
  async (data:any) => {
    let {phone,type } = data
    let phoneClear = phone.replaceAll('(','').replaceAll(')','').replaceAll('-','')
    let item =  await networkLayer.confirmService.requestCode(phoneClear,type)
    return {
      success:item.success
    }
  })
    
export const phoneRequeste = createAsyncThunk(
  'confirm/phoneCall',
  async (phone:string) => {
    let phoneClear = phone.replaceAll('(','').replaceAll(')','').replaceAll('-','')

    let item =  await networkLayer.confirmService.requestCode(phoneClear,VerifyType.Phone)
    return {
      success:item.success
    }
  })


export const confirmCodeCheck = createAsyncThunk(
  'confirm/check',
  async (verify:CheckConfirmCode) => {
    let item =  await networkLayer.confirmService.verifyCode(verify)
    let result =  {
      success:item.success,
      user_exists:item.user
    }
    debugger;
    return result
  })
  