export class RestList<T> {
  contains(arg0: (o: any) => boolean): boolean {
    for (let item of this.items) {
      if (arg0(item)) {
        return true
      }
    }
    return false
  }
  clone(): RestList<T> {
    return new RestList<T>(this.items)
  }
  public items!: Array<T>
  public count!: number
  public offset!: number
  public total_count!: number

  map<U>(callbackfn: (value: T) => U) {
    return this.items.map((a) => callbackfn(a))
  }

  constructor(a?: any, type?: { new(): T }) {
    if (a == null) {
      this.items = []
    } else {
      if (type != null) {
        if (a.items != null) {
          let array = a.items as Array<T>
          this.items = array.map((item) => this.copyTo(item, new type()))
        }
      } else {
        this.items = (a as []).map((item) => this.copyTo(item, {} as T))
      }
      this.count = a.count
      this.offset = a.offset
    }
  }
  public add(item: T) {
    this.items.push(item)
  }
  public remove(item: T) {
    let index = this.items.indexOf(item)
    if (index > -1) {
      this.items.splice(index, 1)
    }
  }
  private copyTo(inValue: any, item: T): T {
    // tslint:disable-next-line:forin
    for (let key in inValue) {
      let value = inValue[key]
      if (
        key.includes('_date') ||
        key.includes('date_') ||
        key === 'date' ||
        key.includes('last_modif')
      ) {
        if (typeof value === 'number') {
          let newDateValue = value + new Date().getTimezoneOffset() * 60 * 1000
            ; (item as any)[key] = new Date(newDateValue)
        } else {
          ; (item as any)[key] = value
        }
      } else {
        ; (item as any)[key] = value
      }
    }

    return item
  }
  concat(item: RestList<T>) {
    this.items = this.items.concat(item.items)
    this.count = this.count + item.count
  }
}

export class Answer<T> {
  public success!: boolean
  public data!: T
  public error!: ErrorDescription
}

export class EmptyAnswer {
  public success!: boolean
  public message!: ErrorDescription
}

export enum ErrorCode {
  WrongInputData = 1,
  ServerError = 2,
  NotFound = 3,
  Exists = 4,
  LastModified = 5,
  NotValidPassword = 6,
  NotValidCode = 7,
  AccessError = 8,
  BannedRequest = 9,
  LimitError = 10,
  ObsoleteMethod = 11,
  Locked = 12,
}
export class ErrorDescription {
  public code!: number
  public message!: string
}
interface QueryParam {
  query(): string
}
export class EnumFilter<T> implements QueryParam {
  private code!: number
  private values!: [T]
  constructor(params: [T]) {
    for (let index = 0; index < params.length; index++) {
      let enumCode = params[index]
      let o = enumCode
    }
  }
  // для запроса - параметры переводит в сптроку для вставки в url
  query(): string {
    return this.code.toString()
  }
}
export class RestCollection<T> extends RestList<T> { }
export class Like implements QueryParam {
  like: string

  constructor(like: string) {
    this.like = like
  }
  query(): string {
    return `like=${this.like}`
  }
}
export class Page implements QueryParam {
  offset: number
  count: number
  constructor(offset: number, count: number) {
    this.offset = offset
    this.count = count
  }
  query(): string {
    return `count=${this.count}&offset=${this.offset}`
  }
}
export class IntRange {
  private StartOperation: string | undefined
  private EndOperation: string | undefined
  public StartInt: number | undefined
  public EndInt: number | undefined
  query(): string {
    if (this.EndOperation == null) {
      return `${this.StartOperation}_${this.StartInt}`
    }
    return `${this.StartOperation}_${this.StartInt}_${this.EndOperation}_${this.EndInt}`
  }
}
export enum DateRangeOperation {
  Eq,
  Gt,
  Lt,
  Ge,
  Le,
  Deq,
  Dgt,
  Dlt,
  Dge,
  Dle,
}
export class DateRange {
  public StartOperation: string
  public EndOperation: string | undefined
  public StartDate: Date
  public EndDate: Date | undefined
  private gettext(operation: DateRangeOperation) {
    switch (operation) {
      case DateRangeOperation.Eq:
        return 'eq'
      case DateRangeOperation.Gt:
        return 'gt'
      case DateRangeOperation.Lt:
        return 'lt'
      case DateRangeOperation.Ge:
        return 'ge'
      case DateRangeOperation.Le:
        return 'le'
      case DateRangeOperation.Deq:
        return 'deq'
      case DateRangeOperation.Dgt:
        return 'dgt'
      case DateRangeOperation.Dlt:
        return 'dlt'
      case DateRangeOperation.Dge:
        return 'dge'
      case DateRangeOperation.Dle:
        return 'dle'
    }
  }

  static from(date: Date): DateRange {
    return new DateRange('lg', date)
  }
  static eq(date: Date): DateRange {
    return new DateRange('eq', date)
  }
  static eqd(date: Date): DateRange {
    return new DateRange('eqd', date)
  }
  static before(date: Date): DateRange {
    return new DateRange('lg', date)
  }
  from(date: Date): DateRange {
    this.EndDate = date
    this.EndOperation = 'lg'
    return this
  }
  before(date: Date): DateRange {
    this.EndDate = date
    this.EndOperation = 'lg'
    return this
  }
  constructor(
    startOperation: string,
    startDate: Date,
    endOperation?: string,
    endDate?: Date
  ) {
    this.StartOperation = startOperation
    this.EndOperation = endOperation
    this.StartDate = startDate
    this.EndDate = endDate
  }
  query(): string {
    if (this.EndOperation == null || this.EndDate == null) {
      return `${this.StartOperation}_${this.StartDate.getTime()}`
    } else {
      return `${this.StartOperation}_${this.StartDate.getTime()}_${this.EndOperation
        }_${this.EndDate.getTime()}`
    }
  }
}
