import { useAppDispatch, useAppSelector } from "../../hooks"

import { LoadingStatus } from "./ieDataSlice"
import moment from 'moment'
import { Loading } from "../../components/loading/Loading";
import { TopLine } from "../../components/top-line/TopLine";
import { useHistory } from "react-router"
import { CategoryLine } from "../../components/category-line/CategoryLine"
import './ie-data.scss';
import useSmartHistory from "../../metrica/metrica";
export function IeData() {
  

    const dispatch = useAppDispatch()
    const navigate = useSmartHistory()

    return (<>
        <TopLine onClickBack={() => { navigate.goBack() }} sectionStyle='left' sectionHeading='' sectionType='icon' sectionIconName='back-button.svg' />
        <CategoryLine categoryText='Данные ИП' />
        <section className="requisites">
            <div className="requisites_line">
                <span className="requisite_heading">Имя:</span>
                <span className="requisite_info">Иван</span>
            </div>
            <div className="requisites_line">
                <span className="requisite_heading">Фамилия:</span>
                <span className="requisite_info">Иванов</span>
            </div>
            <div className="requisites_line">
                <span className="requisite_heading">Отчество:</span>
                <span className="requisite_info">Иванович</span>
            </div>
            <div className="requisites_line">
                <span className="requisite_heading">Номер центрального сервиса:</span>
                <span className="requisite_info">123123123</span>
            </div>
            <div className="requisites_line">
                <span className="requisite_heading">Номер терминала:</span>
                <span className="requisite_info">123123123</span>
            </div>
            <a href="" className="feedback-link">
                <span className="feedback-link_heading">Связаться с разработчиками</span>
                <img src="assets/img/icons/list.svg" alt="" className="feedback-link_pic" />
            </a>
        </section>
    </>)

}
