import { HttpClient } from './share/rest/http.client'
import {
ProductService,
OrderService,
QrService,
TouchService,
} from './client/services'
import { ConfigService } from './client/services/product.service'
import { ConfirmService } from './client/services/confirm.service'
export * from './client/services'
export * from './client/model'
export * from './share'
class NetworkLayer {
configService:ConfigService
productService:ProductService
orderService:OrderService
qrService:QrService
touchService:TouchService
confirmService:ConfirmService
constructor(private httpClient: HttpClient) { 
this.configService = new ConfigService(this.httpClient)
this.productService = new ProductService(this.httpClient)
this.orderService = new OrderService(this.httpClient)
this.qrService = new QrService(this.httpClient)
this.touchService = new TouchService(this.httpClient)
this.confirmService = new ConfirmService(this.httpClient)
}
}
export {  NetworkLayer }
