import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"

export interface HowItWorksState {
  
  howItWorksBlockState: boolean[]; 
}

const initialState: HowItWorksState={
    howItWorksBlockState: [false,false,false,false,false,false,false,false]
}

export const howItWorks = createSlice({
    name: 'howItWorks',
    initialState,
    reducers: {
      onChangeHowItWorksState:(state,action:PayloadAction<number>)=>{
        let index = action.payload;
        state.howItWorksBlockState=state.howItWorksBlockState.map((a,i)=>a==true?false:i==index);
      },
      onCloseAll:(state,action:PayloadAction)=>{
        let index = action.payload;
        state.howItWorksBlockState=state.howItWorksBlockState.map((a,i)=>false);
      }
    }
  })
  
  export const {onChangeHowItWorksState,onCloseAll} = howItWorks.actions
  

  export const selectHowItWorksBlockState = (state: RootState) => state.howItWorks.howItWorksBlockState
  
  export default howItWorks.reducer
