import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"

export interface ProductListState {
}

const initialState: ProductListState = {
}
export const productListSlice = createSlice({
  name: 'productList',
  initialState,
  reducers: {

  }
})

export const { } = productListSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCategories = (state: RootState) => state.home.categories
export const productListState = (state: RootState) => state.productList
export const isSaled = (state: RootState):Boolean=>{
  return state.home.isSaled 
}
export default productListSlice.reducer
