import { useSelector } from 'react-redux';
import './licence-window.scss';

import { useAppDispatch } from '../../hooks';
import { lastTouch } from './licenceWindowSlice';

import { touch } from '../../touchSlice';

export interface LicenceWindowProps {
    onChangeVisible: () => void;
}

export function LicenceWindow(props: LicenceWindowProps) {
    const onChangeVisible = () => {
        props.onChangeVisible()
    }
    let dispatch = useAppDispatch();
    let iframeLoaded = (e: any) => {
        let iframe:HTMLIFrameElement = e.target 
        let contentWindow =  iframe.contentWindow
        if(contentWindow!=null&&contentWindow.document){
           
            contentWindow.document.addEventListener('click',() =>{
                dispatch(lastTouch())
                dispatch(touch())
            });
            contentWindow.document.addEventListener('touchstart',() =>{
               dispatch(lastTouch())
               dispatch(touch())
            });
        }
        
    }

    return (
        <>
            <section className={`card-methods_wrapper`}>
                <div className="darked-background" onClick={onChangeVisible} ></div>
                <div className="licence-window">
                <div className="order-end_warning">
                    <iframe onLoad={(e)=>iframeLoaded(e)} width="900px" height="625px"  
                    // src="/assets/html/terms.html" 
                    src="/api/v1/terms" 
                    />
                </div>
                </div>
            </section>

        </>
    )
}
