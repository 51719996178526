import { createAction } from "@reduxjs/toolkit"
import { OrderState } from "../features/order-status/orderStatusSlice"
import { CreateOrderResponse } from "./signalr"

export interface OrderModel { }
export interface UserModel { }

export interface BoxManagerOrderData {
    order: OrderModel
    user: UserModel
}
export let orderStatus = createAction<OrderState>('order/status')
export let  orderPayed = createAction<OrderState>('order/payed')
export let  buyResult = createAction<boolean>('buy/result')

export let  orderBoxOpened = createAction('box/opened')
