import { createAction } from "@reduxjs/toolkit";
import { ILiteHubClient, LiteHub } from "../network/signalr/lite.hub";
import { host } from '../network/host'
import { createOrderResult } from "../features/order-end/orderEndSlice";
import { OrderState, SaleData, waitOrderReceipt } from "../features/order-status/orderStatusSlice";
import { buyPaymentFailed, loadCategories, orderPaymentFailed, orderPaymentSuccess } from "../network/actions";
import { needReload, onChangeHowItWorksWrapperShow, onCloseHowItWork, startSale, Stats,onUpdateStats } from "../features/home/homeSlice";
import { lastTouch } from "../features/licence-window/licenceWindowSlice";
import { openSaleWindow } from "../features/saled-window/saledWindowSlice";
import { waitProductReceipt } from "../features/product-page/productPageSlice";
import { requestSendedReset } from "../features/sbp-window/sbpWindowSlice";

export interface OrderModel { }
export interface UserModel { }

export interface CreateOrderResponse {
    order_number: string
}
export interface BoxManagerOrderData {
    order: OrderModel
    user: UserModel
}
let liteHub = new LiteHub()
var orderStatus = createAction<BoxManagerOrderData>('order/status')
var orderPayed = createAction<BoxManagerOrderData>('order/status')
var buyResult = createAction<boolean>('buy/result')

var orderBoxOpened = createAction('box/opened')
var conencted = false
let connectionMiddleware = (history: any) => (store: any) => (next: any) => (action: any) => {
    // console.log(`ACTION: ${action.type} ${JSON.stringify(action.payload)}`)
    // //store.dispatch({type:"qr",payload:code})

    if (!conencted) {
        conencted = true;
        liteHub.start(host, <ILiteHubClient>{
            BuyResult: (success: boolean) => {
                if (success) {
                    next(startSale())
                    history.push("/finish-buy")

                } else {
                    debugger
                    next(requestSendedReset())
                    next(buyPaymentFailed())
                }
            },
            OrderPayPreResult: (success: boolean) => {
                // TODO SHOW 
                if (success) {
                    next(waitOrderReceipt())
                }

            },
            OrderPayResult: (success: boolean) => {

                if (success) {
                    next(orderPaymentSuccess())
                    next(startSale())
                    history.push("/finish-order")
                } else {
                    next(orderPaymentFailed())
                }
            },
            SaleStart: (percent: number) => {
                var action = needReload();
                next(action)
                next(openSaleWindow({
                    percent: percent,
                    type: 'simple'

                }))
            },
            SaleStartV2: (data: SaleData) => {
                var action = needReload();
                next(action)
                next(openSaleWindow(data))
            },
            SupportStart: () => {

            },
            BuyPreResult: (success: boolean) => {
                // TODO SHOW
                if (success) {
                    next(waitProductReceipt())
                } else {
                    next(buyPaymentFailed())
                }
            },

            OrderBoxOpened: () => {
                console.log("SignalR: OrderBoxOpened ")
                next(orderBoxOpened())
            },
            CreateOrderResult: (order: CreateOrderResponse) => {
                console.log("SignalR: CreateOrderResult ", order)
                next(createOrderResult(order))
                history.push('/finish');
            },
            OrderPayed: (order: BoxManagerOrderData) => {
                console.log("SignalR: OrderPayed ")
                //history.push("/order-status")
                next(orderPayed(order))
            },
            OrderStatus: (order: OrderState) => {
                console.log("SignalR: OrderStatus ")
                next(lastTouch())
                next({ type: "CLEANUP" })


                next(orderStatus(order))
                history.push("/order-status")

            },
            ReloadCatalogs: () => {
                console.log("SignalR: ReloadCatalogs")
                var action = needReload();
                next(action)

            },
            ReloadPage: () => {
                console.log("SignalR: ReloadPage")
                window.location.href = "/"
                window.location.reload();
            },
            ToHome: () => {
                console.log("SignalR: OrderStatus ")
                var action = onCloseHowItWork();
                next(action)
                history.push("/")

            },
            UpdateStats: (data: Stats) => {
                var action = onUpdateStats(data);
                next(action)
            }
        })
    }

    next(action);
}

export { connectionMiddleware }

