import { useEffect, useState } from "react";


import './stories.scss'
import { useAppDispatch, useAppSelector } from "./hooks";
import { onCloseStory, onStoreisProgress, selectCurrentVideo,incrementVideoIndex } from "./features/stories/storiesSlice";
export interface SplashProp {
    children: React.ReactNode
}

export function Stories(props: SplashProp) {
    const dispatch = useAppDispatch();
    let currentStoryVideo = useAppSelector(selectCurrentVideo)
    const onClickScreeonVideoEndedn = () => {
        dispatch(incrementVideoIndex())

    }
    const onProgress = (e: any) => {
        dispatch(onStoreisProgress(e))
    }
    let isShowStory = currentStoryVideo != null
    const onCloseStoryHandler = () => {
        dispatch(onCloseStory())
    }
    const onClickScreen = async (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(incrementVideoIndex())
    }

    useEffect(() => {
        let splashVideo = document.getElementById('storyVideo')
        splashVideo?.addEventListener('ended', () => {
            onClickScreeonVideoEndedn()
        })
    })

    return (
        <>
            {
                isShowStory ? (
                    <>
                        {props.children}
                         <div className="storyVideoDialogBg" ></div>
                        <div className="storyVideoDialog" >
                            <div className="videoProgressBar">
                                <div className="videoItemProgressBar">
                                    <div className="videoItemProgressBarContent" style={{ width: `${10}%` }} >
                                        
                                    </div>
                                </div>
                                <div className="videoItemProgressBar">
                                    <div className="videoItemProgressBarContent" style={{ width: `${10}%` }} >
                                        
                                    </div>
                                </div>
                            </div>
                            <video id="storyVideo"
                                key={currentStoryVideo}
                                onClick={onClickScreen}
                                onEnded={onClickScreeonVideoEndedn}
                                onProgress={onProgress}
                                autoPlay muted className="storyVideo"
                            >
                                <source
                                    className="footer-video_content"
                                    src={currentStoryVideo ?? ''}
                                    type='video/mp4' />
                            </video>
                            <img src="assets/img/icons/close.svg" onClick={onCloseStoryHandler} alt="" className="close_icon" />
                        </div>


                    </>
                ) : (
                    <>
                        {props.children}
                    </>
                )
            }
        </>

    )
}
