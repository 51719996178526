import './keyboard.scss';

import { selectKeyboardState, selectCurrentLang, selectShiftOn, onChangeShift, onChangeLang } from "./keyboardSlice";
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { TOUCH_TYPE } from '../../config/config';

export interface KeyboardProps {
    continueDisabled: boolean | undefined;
    onClickContinue: () => void;
    onClickBack: () => void;
    onKey: (char: string) => void;
    onBackspace: () => void;
    onEnter: () => void;
}

export function Keyboard(props: KeyboardProps) {
    const dispatch = useAppDispatch()
    let currentLang = useSelector(selectCurrentLang);
    let shiftOn = useSelector(selectShiftOn);
    let numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    let ruSymbols = {
        firstRow: ['Й', 'Ц', 'У', 'К', 'Е', 'Н', 'Г', 'Ш', 'Щ', 'З', 'Х'].map(a=>shiftOn?a:a.toLowerCase()),
        secondRow: ['Ф', 'Ы', 'В', 'А', 'П', 'Р', 'О', 'Л', 'Д', 'Ж'].map(a=>shiftOn?a:a.toLowerCase()),
        thirdRow: ['.','Я', 'Ч', 'С', 'М', 'И', 'Т', 'Ь', 'Б', 'Ю', 'Э' ].map(a=>shiftOn?a:a.toLowerCase())
    }
    let enSymbols = {
        firstRow: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'].map(a=>shiftOn?a:a.toLowerCase()),
        secondRow: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'].map(a=>shiftOn?a:a.toLowerCase()),
        thirdRow: [ '.','Z', 'X', 'C', 'V', 'B', 'N', 'M', ',',].map(a=>shiftOn?a:a.toLowerCase())
    }
    let symbols = currentLang == 'ru' ? ruSymbols : enSymbols;
    let onKey = (e: any, a: string) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        props.onKey(shiftOn ? a.toUpperCase() : a.toLowerCase())
    }
    let onBackspace = (e: any) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        props.onBackspace()
    }
    let onEnter = (e: any) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        props.onEnter()
    }
    let onClickBack = (e: any) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        props.onClickBack()
    }

    let onClickContinue = (e: any) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        if (props.continueDisabled == false) { props.onClickContinue() }
    }

    let onChangeLangAction = (e: any) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        dispatch(onChangeLang())
    }

    let onChangeShiftAction = (e: any) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        dispatch(onChangeShift())
    }
    let largeButtons =currentLang=='ru'? '01фж':'01';
    let primaryButtons =currentLang=='ru'? '.,':'.,';
    let renderButton = (a:string)=>(
        <button key={a} className={`keyboard_button ${largeButtons.indexOf(a.toLowerCase())>-1?'large':''} ${
            primaryButtons.indexOf(a.toLowerCase())>-1?'primary':''
        }`}
            onTouchStart={(e) => onKey(e, a)}
            onClick={(e) => onKey(e, a)}
        >{a}</button>)
    

    return (<div className="keyboard">
        <div className="keyboard_line numbers">
            {
                numbers.map(a => renderButton(a))
            }
           
        </div>
        <div className="keyboard_line">
            {
                symbols.firstRow.map(a =>renderButton(a))
            }
        </div>
        <div className="keyboard_line">
            {
                symbols.secondRow.map(a =>renderButton(a))
            }
            
        </div>
        <div className="keyboard_line">
            
            {
                symbols.thirdRow.map(a =>renderButton(a))
            }
        </div>
        <div className="keyboard_line keyboard_line_around">
        {/* <button className="keyboard_button black"
                onTouchStart={onChangeShiftAction}
                onClick={onChangeShiftAction}
            >
                <img src="assets/img/icons/shift.png" alt="" className="keyboard_button_pic"></img>
            </button> */}
            <button
                onTouchStart={onChangeLangAction}
                onClick={onChangeLangAction}
                className="keyboard_button texted black lang">
                {currentLang=='ru'?"РУС":"ENG"}
            </button>
            <button
                onTouchStart={(e) => onKey(e, ' ')}
                onClick={(e) => onKey(e, ' ')}
                className="keyboard_button space black">
                    <div className='line'></div>
                </button>
            <button
                onTouchStart={onBackspace}
                onClick={onBackspace}
                className="keyboard_button long backspace">
                <img src="/assets/img/backspace.svg" alt="" className="keyboard_button_pic"></img>
            </button>
            <button
                onTouchStart={onEnter}
                onClick={onEnter}
                className="keyboard_button enter texted black">
                ВВОД
            </button>
        </div>
        {/* <div className="keyboard_line">
            <button
                onTouchStart={onClickBack}
                onClick={onClickBack}
                className="main-button black">Назад</button>
            <button
                onTouchStart={onClickContinue}
                onClick={onClickContinue}
                className={`main-button ${!props.continueDisabled ? 'yellow' : ' disabled'}`}>Далее</button>
        </div> */}
    </div>)
}
