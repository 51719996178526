import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DownVideo } from "../../components/down-video/DownVideo";
import { TopLine } from "../../components/top-line/TopLine";
import './repair-options.scss';
import { RepairMode, repairMode } from "../order-end/orderEndSlice";
import useSmartHistory from "../../metrica/metrica";
import { selectBoxId,selectIsFreeOrder } from "../home/homeSlice";
import { selectExpireDate, selectExpireDateValue } from "../saled-window/saledWindowSlice";

export function RepairOptions() {
    let navigate = useSmartHistory();
    let dispatch = useDispatch()
    let isFreeOrder = useSelector(selectIsFreeOrder)
    let expireDate = useSelector(selectExpireDateValue) 
    let toNext = (mode: RepairMode) => {

        dispatch({
            type: "CLEANUP",
            mode: mode
        })
        //  dispatch(repairMode(mode))
        navigate.push('/order-defects')


    }
    let targetDate = (expireDate??new Date()).getTime()
    const currentDate = new Date().getTime();
   
    // Создаём переменную с целевой датой - 20 июля 2024 года
    // const targetDate = new Date('2024-09-19').getTime();

    // Вычисляем разницу в миллисекундах между двумя датами
    const differenceInMillis = targetDate - currentDate;

    // Преобразуем миллисекунды в дни (1 день = 24 часа * 60 минут * 60 секунд * 1000 миллисекунд)
    const millisInDay = 24 * 60 * 60 * 1000;
    const daysCount = Math.ceil(differenceInMillis / millisInDay);

    // Выводим количество дней до целевой даты
    console.log(`Количество дней до 20 июля 2024 года: ${daysCount}`);
    let isAction = daysCount>0 && isFreeOrder 
    return (<>
        <TopLine sectionStyle='left' sectionText='!' sectionHeading='' sectionType='text' >
            <div> Выберите условия <span className="primaryText">ремонта</span> </div>
        </TopLine>
        <div className="above-buttons-block">
            <section className="repair-options above-buttons-block">
                { isAction ?(
 <div onClick={() => {
    toNext(RepairMode.Free)
}} className="repair-option free">

    <h2 className="repair-option_heading">
        Акция
    </h2>
    <div className="repair-option_heading2"><div className="bigger">Бесплатный ремонт*</div>
    </div>
    <div className="repair-option_heading3">
        осталось {daysCount} дней         
        </div>
    <div className="repair_option_list">

        <div className="repair_option_item">
            <div className="repair_option_item_price">0р</div>
            <div className="repair_option_item_text">Стоимость услуги</div>
        </div>
        <div className="repair_option_item">
            <div className="repair_option_item_price">48</div>
            <div className="repair_option_item_text">часов на ремонт</div>
        </div>
        <div className="repair_option_item">
            <div className="repair_option_item_price">30</div>
            <div className="repair_option_item_text">дней гарантия</div>
        </div>
    </div>
    <div className="notion">
        *вы оплачиваете только стоимость запчасти
    </div>
</div>
                ):(
                        <>
                        <div onClick={() => {
                    toNext(RepairMode.Cheper)
                }} className="repair-option cheper">
                    <div className="icon_contianer">
                        <img src="assets/img/piggy-bank.png"
                            alt="" className="repair-option_icon" />
                    </div>
                    <h2 className="repair-option_heading">
                        Подешевле
                    </h2>
                    <div className="repair_option_list">
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">590 р.</div>
                            <div className="repair_option_item_text">цена услуги*</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">48</div>
                            <div className="repair_option_item_text">часов на ремонт</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">15</div>
                            <div className="repair_option_item_text">дней гарантия</div>
                        </div>
                    </div>
                    <div className="notion">
                        *без учета стоимости запчастей
                    </div>
                </div> 
                
                <div onClick={() => {
                    toNext(RepairMode.Faster)
                }} className="repair-option faster">

                    <div className="icon_contianer">
                        <img src="assets/img/rocket.png"
                            alt="" className="repair-option_icon" />
                    </div>
                    <h2 className="repair-option_heading">
                        Побыстрее
                    </h2>
                    <div className="repair_option_list">
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">990 р.</div>
                            <div className="repair_option_item_text">цена услуги*</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">24</div>
                            <div className="repair_option_item_text">часа на ремонт</div>
                        </div>
                        <div className="repair_option_item">
                            <div className="repair_option_item_price">30</div>
                            <div className="repair_option_item_text">дней гарантия</div>
                        </div>
                    </div>
                    <div className="notion">
                        *без учета стоимости запчастей
                    </div>
                </div> 
                </>

                )}
                


            </section>
        </div>

        <div className="defects_buttons">
            <button onClick={() => { navigate.push('/') }} className="main-button black back">Назад</button>
            <button className={`main-button yellow next invisible`} >Далее</button>
        </div>

        <DownVideo />
    </>)

}
