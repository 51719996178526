import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"
import { SaleData } from "../order-status/orderStatusSlice"
export interface SaledWindowState {
  isDialogOpen: boolean,
  percent: number,
  dialog_type?: string|null
  is_show_alert: boolean 
  is_free_order: boolean
  expire_date?: Date|null
 
  used_promo_index: number ;
}

const initialState: SaledWindowState = {
  isDialogOpen: false,
  percent: 0,
  is_show_alert:false,
  is_free_order:false,

  used_promo_index:-1,

}

export const saledWindow = createSlice({
  name: 'saledWindow',
  initialState,
  reducers: {
    closeWindow: (state) => {
      state.isDialogOpen = false
    },
    openSaleWindow: (state,action) => {
      let saleData = action.payload as SaleData
      state.percent = saleData.discount_percent
      if (saleData.expire_date!=null){
        let expireDate= new Date(saleData.expire_date)
        state.expire_date = expireDate
      }
   
      state.is_free_order = saleData.is_free_order
      state.dialog_type = saleData.dialog_type
      state.isDialogOpen = saleData.dialog_type!='promo'
     
    },
    openPromoWindow: (state,action) => {
      state.dialog_type = 'promo'
      state.isDialogOpen = true
      state.used_promo_index = action.payload.index
    },
    timeTick:(state)=>{
      
    },
   
    lastTouch:(state)=>{
    
    },
   
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {

    });

  }
})

export const { closeWindow,lastTouch, timeTick, openSaleWindow ,openPromoWindow} = saledWindow.actions



export const selectIsOpen = (state: RootState) => {
  return state.saledWindow.isDialogOpen
}
export const selectPercent = (state: RootState) => {
  return state.saledWindow.percent
}
export const selectType = (state: RootState) => {
  return state.saledWindow.dialog_type
}
export const selectExpireDateValue = (state: RootState) => {
  return state.saledWindow.expire_date;
}
export const selectExpireDate = (state: RootState) => {
  let date = state.saledWindow.expire_date;
  if(date==null){
    return ''
  }
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, so add 1) and pad with zero if needed

  return `${day}.${month}`;
}
export const selectPromoExpireDate = (state: RootState) => {
  let expire_date = state.home.promo?.expiration_date;
  if(expire_date==null){
    return ''
  }
  let date = new Date(expire_date)
  const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed, so add 1) and pad with zero if needed

  return `${day}.${month}`;
}


export default saledWindow.reducer
