import './checkbox.scss';

export interface CheckboxProps{
    checked: boolean;
    heading: string;
    color: string|null;
    onChange: ()=> void; 
    children?: React.ReactNode
    // prompt: boolean;
}

export function Checkbox(props: CheckboxProps) {   
    return (
        <button onClick={props.onChange} className={`custom-checkbox_wrapper ${props.checked == true ? 'checked' : ''}  ${props.color == "yellow" ? 'yellow' : ''}`}>
            <div className="custom-checkbox" />
            <span className="checkbox_heading">
                {props.heading}
                {props.children}
            </span>
        </button>
    )
}
