import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { DownVideo } from '../../components/down-video/DownVideo';
import { Keyboard } from "../../features/keyboard/Keyboard";
import { OrderStages } from '../../components/order-stages/OrderStages';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { onKey, onBackspace, selectOrderCommentText, onEnter } from './orderCommentSlice';
import './order-comment.scss';
import { useEffect, useRef } from 'react';
import useSmartHistory from '../../metrica/metrica';
import { selectConfirmUserExists, selectIsPhoneConfirmed } from '../confirm-phone/confirmPhoneSlice';
/*
function getCaretPosition(editableDiv) {
  var caretPos = 0,
    sel, range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      if (range.commonAncestorContainer.parentNode == editableDiv) {
        caretPos = range.endOffset;
      }
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    if (range.parentElement() == editableDiv) {
      var tempEl = document.createElement("span");
      editableDiv.insertBefore(tempEl, editableDiv.firstChild);
      var tempRange = range.duplicate();
      tempRange.moveToElementText(tempEl);
      tempRange.setEndPoint("EndToEnd", range);
      caretPos = tempRange.text.length;
    }
  }
  return caretPos;
}
*/
export function OrderComment() {
  let navigate = useSmartHistory()
  let text = useSelector(selectOrderCommentText);
  let isUserExists = useAppSelector(selectConfirmUserExists);
  let isPhoneConfirmed = useAppSelector(selectIsPhoneConfirmed);
  const dispatch = useAppDispatch();
  let textPrompt = text == '' ? 'Введите комментарий' : text;
  let textContent = textPrompt.split('\n').map(str => <p>{str}</p>)
  let commentRef = useRef(null)
  let isCanContinue = true;
  useEffect(() => {
    let element = commentRef.current as any;
    let w = window as any
    if (element.type !== "textarea" && element.getAttribute("contenteditable") === "true") {
      element.focus()
      w.getSelection().selectAllChildren(element)
      w.getSelection().collapseToEnd()
    } else {
      // Place cursor at the end of text areas and input elements
      element.focus()
      element.select()
      w.getSelection().collapseToEnd()
    }
  })
  return (
    <>
      <OrderStages currentStage={0} />
      <div className="above-buttons-block">
        <CategoryLine categoryText='Введите комментарий' />
        <div className="enter_comment_layout">
          <div className="comment_container">
            <section className="commentary">
              <div contentEditable ref={commentRef} className={`commentary_text ${text == '' ? ' placeholdered' : ''}`}>{textContent}</div>
            </section>
          </div>
          <div className='keyboard_container'>
            <Keyboard onEnter={() => dispatch(onEnter())} continueDisabled={text == "" ? true : false}
              onBackspace={() => { dispatch(onBackspace()); }}
              onKey={(a) => { dispatch(onKey(a)); }}
              onClickContinue={() => { navigate.push('/confirm-phone'); }}
              onClickBack={() => { navigate.push('/order-defects'); }}
            />
          </div>

        </div>
      </div>

      <div className="defects_buttons">
        <button onClick={() => { navigate.push('/order-defects'); }} className="main-button black back">Назад</button>
        <button onClick={() => {
          if (isPhoneConfirmed) {
            if (isUserExists) {
              navigate.push('/phone-data');
            } else {
              navigate.push('/client-data');
            }
          } else {
            navigate.push('/confirm-phone');
          }
        }} className={`main-button yellow next ${(isCanContinue ? '' : 'disabled')}`} disabled={!isCanContinue}>Далее</button>
      </div>
      <DownVideo />
    </>

  )

}
