import { useHistory } from 'react-router-dom';
import './footer.scss';
import { Support } from '../support/Support';
import useSmartHistory from '../../metrica/metrica';
import { selectBoxId, selectSupportPhone } from '../../features/home/homeSlice';
import { useSelector } from 'react-redux';
import { VERSION } from '../../version';
export interface FooterProps{
}

export function Footer() {
    let navigate = useSmartHistory();
    let supportPhone = useSelector(selectSupportPhone)
    return (<footer>
        <Support supportPhone={supportPhone} />
       
        <div className='version'>{VERSION}</div>
    </footer>)
}
