import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"

export enum LoadingStatus{
    Loaded, Loading,Eror
}
export interface QrScan{

}
export interface QrScanState {
}
const initialState: QrScanState={
    status:LoadingStatus.Loaded,
    data :{

    }
}
export const qrScanSlice = createSlice({
    name: 'qrScan',
    initialState,
    reducers: {
        
    }
  })
  
  export const {} = qrScanSlice.actions
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectQrScanState = (state: RootState) => state.qrScan
  export const selectLoadingStatusState = (state: RootState) => state.qrScan
  
  export default qrScanSlice.reducer
