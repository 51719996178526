import './numboard.scss';

import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../hooks';
import { TOUCH_TYPE } from '../../config/config';

export interface NumboardProps {
    onKey: (char: string) => void;
    onBackspace: () => void;
}

export function Numboard(props: NumboardProps) {
    const dispatch = useAppDispatch()
    let symbols = {
        firstRow: ['1', '2', '3'],
        secondRow: ['4', '5', '6'],
        thirdRow: ['7','8', '9' ]
    }
   
    let onKey = (e: any, a: string) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        props.onKey(a)
    }
    let onBackspace = (e: any) => {
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        props.onBackspace()
    }

    let renderButton = (a:string)=>(
        <button key={a} className={`numboard_button`}
            onTouchStart={(e) => onKey(e, a)}
            onClick={(e) => onKey(e, a)}
        >{a}</button>)
    

    return (<div className="numboard">
        <div className="numboard_line">
            {
                symbols.firstRow.map(a => renderButton(a))
            }
           
        </div>
        <div className="numboard_line">
            {
                symbols.secondRow.map(a =>renderButton(a))
            }
        </div>
        <div className="numboard_line">
            {
                symbols.thirdRow.map(a =>renderButton(a))
            }
            
        </div>
        <div className="numboard_line right">
        {renderButton('0')}
        <button
                onTouchStart={onBackspace}
                onClick={onBackspace}
                className="numboard_button long backspace">
                <img src="/assets/img/backspace.svg" alt="" className="numboard_button_pic"></img>
                </button>
            
        </div>
     
    </div>)
}
