import './sbp-window.scss';

import { closePaymentWindow, isRequestSendedSelector, nfcPaymentShow, productQrText, qrPaymentShow, requestSended, selectOrderSecret } from "./sbpWindowSlice";
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    nfcPayProduct, checkOrderPayStatus,
    checkProductPayStatus, nfcPayOrder,
    productQrPayText, orderQrPayText, spbClose
} from '../../network/actions';
import { selectProductId } from '../product-page/productPageSlice';
import { useEffect } from 'react';
import { host } from '../../network/host';

export interface SbpWindowProps {
    onChangePaymentWindowShow: () => void;
}

export function SbpWindow(props: SbpWindowProps) {
    const dispatch = useAppDispatch();

    let productId = useSelector(selectProductId)

    let isRequestSended = useSelector(isRequestSendedSelector)
    let secret = useSelector(selectOrderSecret)
    let qrText = useSelector(productQrText)

    if (qrText == null) {

        if (!isRequestSended) {
            dispatch(requestSended())
            if (secret == null) {
                dispatch(productQrPayText(productId))
            } else {
                dispatch(orderQrPayText(secret))
            }
        }
    }

    const changePaymentShowMode = () => {
        dispatch(spbClose())
        closePaymentWindow()
        props.onChangePaymentWindowShow()
    }
    // const checkPay = () => {
    //     if (secret == null) {
    //         dispatch(checkProductPayStatus(productId))
    //     } else {
    //         dispatch(checkOrderPayStatus(secret))
    //     }
    // }
    console.log(` qrText: ${qrText}`)
    return (
        <section className={`sbp-methods_wrapper`}>
            <div className="darked-background" onClick={changePaymentShowMode}></div>

            <div className="payment-methods">
                <div className="qr-description-raw">
                    <div className="qr-description">
                        <img src="/assets/img/sbp-bigone.png" alt="" />
                        <div className='qr-description-text'>
                            Отсканируйте
                            штрихкод
                            с помощью
                            вашего
                            устройства
                            и произведите
                            оплату
                        </div>
                    </div>
                    <div className='qr-code'>
                        {
                            qrText != null ? (
                                <img src={host + '/api/v1/qr/1/30?text=' + qrText} alt="" className="method-description-pic" />

                            ) : null
                        }
                    </div>
                </div>
                <div>

                </div>


            </div>
        </section>
    )
}
