import { HttpClient } from './http.client'
import { Answer, DeviceDataModel, DeviceModel } from './models'
import { StorageService } from './storage.service'

export class TokenService {
  private hostUrl: string | undefined

  private store: StorageService
  constructor(private http: HttpClient) {
    this.hostUrl = ''

    this.store = new StorageService()
  }

  public getDeviceData(): DeviceDataModel {
    return {
      device_id: this.generateGuid(),
      device_type: 1,
      os_version: 'web',
      app_type: 1,
      app_build: 0,
      app_version: '1.0.0',
    } as DeviceDataModel
  }
  public initDevice() {
    if (
      this.store.getItem('device_token') == null &&
      localStorage.getItem('access_token') == null
    ) {
      this.initDeviceToken().then((answer) => {
        if (answer.success) {
          let a = answer.data
          this.store.setItem('device_token', a.access_token)
          this.store.setItem('access_token_hash', a.access_token_hash)
          this.store.setItem('token_type', a.token_type)
          this.store.setItem('expires_in', a.expires_in.toString())
          this.store.setItem('api_host', a.api_host)
          this.store.setItem('date', a.date.toString())
          this.store.setItem('need_sync', String(a.need_sync))
        }
      })
    }
  }
  public initDeviceToken(): Promise<Answer<DeviceModel>> {
    return this.initDeviceTokenMethod(this.getDeviceData())
  }
  private initDeviceTokenMethod(
    deviceData: DeviceDataModel
  ): Promise<Answer<DeviceModel>> {
    const url = `/api/v1/device`
    return this.http.post<Answer<DeviceModel>>(url, undefined, deviceData)
  }
  public refreshDeviceToken(): Promise<Answer<DeviceModel>> {
    const url = `/api/v1/device/token/refresh`
    return this.http.post<Answer<DeviceModel>>(
      url,
      undefined,
      this.getDeviceData()
    )
  }
  public refreshAccountToken(): Promise<Answer<DeviceModel>> {
    return this.refreshAccountMethod(this.getDeviceData())
  }
  private refreshAccountMethod(
    deviceData: DeviceDataModel
  ): Promise<Answer<DeviceModel>> {
    const url = `/api/v1/account/token/refresh`
    return this.http.post<Answer<DeviceModel>>(url, undefined, deviceData)
  }
  private generateGuid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    return (
      s4() +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      '-' +
      s4() +
      s4() +
      s4()
    )
  }
  public logout(): Promise<boolean> {
    const url = `${this.hostUrl}/api/v1/account/logout`
    return this.http
      .post<Answer<DeviceModel>>(url, undefined, this.getDeviceData())
      .then((a) => {
        if (a.success) {
          this.store.setItem('device_token', a.data.access_token)
        } else {
          this.store.setItem('device_token', null)
        }
        this.store.setItem('access_token', null)
        return true
      })
  }
}
