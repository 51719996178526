import { AnyAction, configureStore, Middleware, Reducer } from '@reduxjs/toolkit'
import counter, { CounterState } from '../features/counter/counterSlice'
import orderStatus, { OrderStatusState } from '../features/order-status/orderStatusSlice'
import orderComment from '../features/order-comment/orderCommentSlice'
import orderDefects from '../features/order-defects/orderDefectsSlice'
import orderEnd from '../features/order-end/orderEndSlice'
import home from '../features/home/homeSlice'
import clientData from '../features/client-data/clientDataSlice'
import confirmPhone from '../features/confirm-phone/confirmPhoneSlice'
import phoneData from '../features/phone-data/phoneDataSlice'
import productPage from '../features/product-page/productPageSlice'
import productList from '../features/product-list/productListSlice'
import qrScan from '../features/qr-scan/qrScanSlice'
import keyboard from '../features/keyboard/keyboardSlice'
import timerWindow from '../features/timer-window/timerWindowSlice'
// import contractOffer from '../features/contract-offer/contractOfferSlice'
import touch from '../touchSlice'
// ...
import { connectRouter, RouterState } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import  qrMiddleware  from './qrmid'
import howItWorks from '../features/how-it-works/howItWorksSlice'
import { connectionMiddleware } from './signalr'
import sbpWindow  from '../features/sbp-window/sbpWindowSlice'
import saledWindow from '../features/saled-window/saledWindowSlice'
import supportWindow from '../features/support-window/supportWindowSlice'
import stories from '../features/stories/storiesSlice'
export const history = createBrowserHistory()

let router:Reducer<RouterState<any>,AnyAction> = connectRouter(history)
interface Recucers {
  router:Reducer<RouterState<any>,AnyAction>
  counter:Reducer<CounterState,AnyAction>
  orderStatus:Reducer<OrderStatusState,AnyAction>
}

export const store = configureStore({
  reducer: {
    router,
    counter,
    orderStatus,
    orderComment,
    orderDefects,
    orderEnd,
    home,
    clientData,
    phoneData,
    productPage,
    productList,
    qrScan,
    touch,
    keyboard,
    howItWorks,
    sbpWindow,
    timerWindow,
    saledWindow,
    supportWindow,
    confirmPhone,
    stories,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) => {
    let middlewares=getDefaultMiddleware(
     { 
      serializableCheck: false
    }
    );
    let connnectionmiddleWare = connectionMiddleware(history)
   
    return middlewares.concat(qrMiddleware,connnectionmiddleWare)
     
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
