import { HttpClient } from './http.client'
import { Answer, ErrorCode, Page, RestList } from './model'
import { StorageService } from './storage.service'
import { TokenService } from './token.service'

export class BaseService {
  protected _tokenService: TokenService
  protected host_url: string = "";// "http://rembox.smartapi.ru"
  protected store: StorageService
  constructor(private http: HttpClient) {
    this.store = StorageService.instance
    this._tokenService = new TokenService(http)
  }
  protected enumFilterValue<T>(ar: T[]): number {
    let value = 0
    for (let item of ar) {
      value |= 1 << +item
    }
    return value
  }
  private parseDate<T>(inValue: any): T {
    let out = {}
    // tslint:disable-next-line:forin
    for (let key in inValue) {
      let value = inValue[key]
      if (key.includes('_date') || key.includes('date_') || key === 'date') {
        if (typeof value === 'number') {
          ; (out as any)[key] = new Date(value)
        } else {
          ; (out as any)[key] = value
        }
      } else {
        ; (out as any)[key] = value
      }
    }
    return out as T
  }
  protected response<T>(request: () => Promise<Answer<T>>): Promise<T> {
    let httpRequest = request()
    let obin: (o: Answer<T>) => Promise<T> = (res: Answer<T>) => {
      if (res.success==null){
        let obj = this.parseDate<T>(res) as T
        return Promise.resolve(obj)
      } 
      if (res.success) {
        let obj = this.parseDate<T>(res.data) as T
        return Promise.resolve(obj)
      } else {

        let code = res.error.code as ErrorCode
        switch (code) {
          case ErrorCode.AccessError:
            let device_token = this.store.getItem('device_token')
            let access_token = this.store.getItem('access_token')
            if (device_token == null && access_token != null) {
              return this._tokenService.refreshAccountToken().then((a) => {
                if (a.success) {
                  this.store.setItem('access_token', a.data.access_token)
                  this.http.setAccountToken(a.data.access_token)
                  return this.response<T>(request)
                } else {
                  throw new Error(a.message.message)
                }
              })
            } else if (device_token != null && access_token == null) {
              return this._tokenService.refreshDeviceToken().then((a) => {
                if (a.success) {
                  this.store.setItem('device_token', a.data.access_token)
                  this.http.setDeviceToken(a.data.access_token)
                } else {
                  this.store.setItem('device_token', null)
                }
                return this.response<T>(request)
              })
            } else if (device_token == null && access_token == null) {
              return this._tokenService.initDeviceToken().then((a) => {
                if (a.success) {
                  this.store.setItem('device_token', a.data.access_token)
                  this.http.setDeviceToken(a.data.access_token)
                  return this.response<T>(request)
                } else {
                  throw new Error(a.message.message)
                }
              })
            }
            break
          case ErrorCode.NotFound:
            // this._alertService.create('warning', res.message.message);
            break
        }
      }
      throw new Error(res.error.message)
    }
    return httpRequest.then(obin)
  }

  protected _getCollectionCanck<T>(
    url: string,
    pager: Page
  ): Promise<RestList<T>> {
    let newUrl = url
    if (url.indexOf('?') === -1) {
      newUrl += '?'
    } else {
      newUrl += '&'
    }
    let count = pager.count
    let offset = pager.offset
    newUrl += `count=${count}&offset=${offset}`
    return this.response(() =>
      this.http.get<Answer<RestList<T>>>(newUrl, undefined)
    )
  }
  protected _getCollectionWithInstance<T>(
    type: { new(): T },
    url: string
  ): Promise<RestList<T>> {
    return this.response(() =>
      this.http.get<Answer<RestList<T>>>(url, undefined)
    ).then((a) => {
      return new RestList<T>(a, type)
    })
  }

  protected _getCollection<T>(url: string): Promise<RestList<T>> {
    return this.response(() =>
      this.http.get<Answer<RestList<T>>>(url, undefined)
    )
  }
  protected _get<T>(url: string): Promise<T> {
    return this.response(() => this.http.get<Answer<T>>(url, undefined))
  }
  protected _post<T>(url: string, param?: any): Promise<T> {
    return this.response(() =>
      this.http.post<Answer<T>>(url, undefined, param)
    )
  }
  protected _postWithoutWrapper<T>(url: string, param?: any): Promise<T> {
    return this.http.post<T>(url, undefined, param)
  }
  protected _put<T>(url: string, param?: any): Promise<T> {
    return this.response(() =>
      this.http.put<Answer<T>>(url, undefined, param)
    )
  }
  protected _delete<T>(url: string): Promise<T> {
    return this.response(() => this.http.delete<Answer<T>>(url, undefined))
  }
}
