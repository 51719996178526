import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import { TOUCH_TYPE } from '../../config/config';
import useSmartHistory from '../../metrica/metrica';


export function FinishEnvelopeTake() {
    let navigate = useSmartHistory();
    let onNextPage = (e:any) =>{
        if(e.type != TOUCH_TYPE){
            return
        }
        
        navigate.push('/finish');
    }
    let onPrevPage = (e:any) =>{
        if(e.type != TOUCH_TYPE){
            return
        }
        navigate.goBack();
    }
    return (<>
        <OrderStages currentStage={3} />
        <CategoryLine categoryText='Завершение оформления' />
        <section className="finish-block">
            {/* <img src="assets/img/end-pic.png" alt="" className="finish-block_img" /> */}
            <video autoPlay loop muted width="500" height="500" className="finish-block_video">
                <source className="footer-video_content" src="/assets/videos/finish-3.mp4" type='video/mp4'/>
            </video>
            <p className="finish-block_line">
                1. Положите конверт 
                <br/>
                2. Закройте ячейку в ячейку 
                <br/> 
                3. Получите квитанцию
            </p>
        </section>
    </>)
}
