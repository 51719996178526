import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import { TOUCH_TYPE } from '../../config/config';
import useSmartHistory from '../../metrica/metrica';

export function FinishCards() {
    let navigate = useSmartHistory();
    let onNextPage = (e: any) => {
        if (e.type != TOUCH_TYPE) {
            return
        }

        navigate.push('/finish-envelope-put');
    }
    let onPrevPage = (e: any) => {
        if (e.type != TOUCH_TYPE) {
            return
        }
        navigate.goBack();
    }
    let isCanContinue = true;
    return (<>
        <OrderStages currentStage={3} />
        <div className="above-buttons-block">
            <CategoryLine categoryText='Завершение оформления' />
            <section className="finish-block">
                <div className="image-with-steps">
                    <div className="image-container">
                    <video autoPlay loop muted  height="" className="finish-video">
                        <source className="footer-video_content" src="/assets/videos/finishvideo1.mp4" type='video/mp4'/>
                    </video>
                        {/* <img src={endimage} alt="" /> */}
                    </div>
                    <div className="steps-container">
                        <div className="step-list">
                            <div className="step-list-item">
                                <div className="step-list-point">1</div>
                                <div className="step-list-item-text">
                                    В открывшемся ящике возьмите конверт
                                </div>
                            </div>
                           
                            <div className="step-list-item">
                                <div className="step-list-point">2</div>
                                <div className="step-list-item-text">
                                Положите в конверт телефон

                                </div>
                            </div>
                            <div className="step-list-item">
                                <div className="step-list-point">3</div>
                                <div className="step-list-item-text">
                                    Заклейте
                                    конверт
                                </div>
                            </div>
                          
                            <div className="step-list-item">
                            <div className="step-list-point">4</div>
                            <div className="step-list-item-text">
                                Положите конверт
                                в ячейку
                            </div>
                        </div>
                        <div className="step-list-item">
                            <div className="step-list-point">5</div>
                            <div className="step-list-item-text">
                                Закройте ячейку
                                и получите квитанцию
                            </div>
                        </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>

        <div className="defects_buttons">
            <button className="main-button black back disabled invisible">Назад</button>
            <button  className={`main-button yellow next disabled invisible`}>Далее</button>
        </div>
   

    </>)
}
