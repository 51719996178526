import { Children } from 'react';
import './touch-effect.scss';
export interface TouchEffectProps{
    children: JSX.Element;
}
export function TouchEffect(props: TouchEffectProps) {
var soundEffect = new Audio('/assets/audio/click.mp3');
return (
<div onClick={()=>{soundEffect.play();}}>
    {props.children}
</div>
)
}