import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"
export interface TimerWindowState {
  timer: number
  lastTouch: number
  isOpen: boolean,
  isTimering: boolean,

}

const initialState: TimerWindowState = {
  timer: -1,
  isOpen: false,
  isTimering:false,
  lastTouch: new Date().getTime()
}

export const timerWindow = createSlice({
  name: 'timerWindow',
  initialState,
  reducers: {
    closeWindow: (state) => {
      state.isOpen = false
      state.timer = -1
    },
    openWindow: (state) => {
      state.isOpen = true
      state.timer = 30
      
    },
    timeTick:(state)=>{
      state.timer = state.timer - 1;
    },
   
    lastTouch:(state)=>{
      state.lastTouch = new Date().getTime()
    },
   
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {

    });

  }
})

export const { closeWindow,lastTouch, timeTick, openWindow } = timerWindow.actions

export const selectTimerValue = (state: RootState) => {
  return state.timerWindow.timer
}

export const selectIsOpen = (state: RootState) => {
  return state.timerWindow.isOpen
}
export const selectIsTimering = (state: RootState) => {
  return state.timerWindow.isTimering
}
export const selectLastTouchDate = (state: RootState) => {
  return state.timerWindow.lastTouch
}

export default timerWindow.reducer
