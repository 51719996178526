import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import { TOUCH_TYPE } from '../../config/config';

import endimage from '../../assets/img/end-pic2.png'
import useSmartHistory from '../../metrica/metrica';

export function FinishEnvelopePut() {
    let navigate = useSmartHistory();
    let onNextPage = (e: any) => {
        if (e.type != TOUCH_TYPE) {
            return
        }
        navigate.push('/finish-envelope-take');
    }
    let onPrevPage = (e: any) => {
        if (e.type != TOUCH_TYPE) {
            return
        }
        navigate.goBack();
    }
    return (<>
        <OrderStages currentStage={3} />
        <div className="above-buttons-block">
        <CategoryLine categoryText='Завершение оформления' />
        <section className="finish-block">
            <div className="image-with-steps">
                <div className="image-container">
                <video autoPlay loop muted  height="" className="finish-video">
                        <source className="footer-video_content" src="/assets/videos/finishvideo2.mp4" type='video/mp4'/>
                    </video>
                    {/* <img src={endimage} alt="" /> */}
                </div>
                <div className="steps-container">
                    <div className="step-list">
                        <div className="step-list-item">
                            <div className="step-list-point">6</div>
                            <div className="step-list-item-text">
                                Положите конверт
                                в ячейку
                            </div>
                        </div>
                        <div className="step-list-item">
                            <div className="step-list-point">7</div>
                            <div className="step-list-item-text">
                                Закройте ячейку
                                и получите квитанцию
                            </div>
                        </div>
                        <div className="step-list-item">
                            <div className="step-list-point">8</div>
                            <div className="step-list-item-text">
                                Квитанцию не теряйте. Забрать сможете при помощи QR кода.

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        </div>
      
        <div className="defects_buttons">
            <button className="main-button black back disabled invisible">Назад</button>
            <button  className={`main-button yellow next disabled invisible`}>Далее</button>
        </div>
        {/* <div className="finish-block_buttons">
            <button onTouchStart={onNextPage} onClick={onNextPage} className="main-button yellow">Далее</button>
        </div> */}
    </>)
}
