import { Subject } from 'rxjs';
import { share } from 'rxjs/operators';

declare global {
  interface Window {
    INITIALIZED: boolean | undefined;
  }
}
export class StorageService {
  private onSubject = new Subject<{ key: string; value: any }>()
  public changes = this.onSubject.asObservable().pipe(share())
  public static instance = new StorageService()
  constructor() {
    this.start()
  }

  ngOnDestroy() {
    this.stop()
  }
  public removeItem(key: string) {
    localStorage.removeItem(key)
  }
  public getStorage() {
    let s = []
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i)
      if (key == null) {
        continue
      }
      let v = localStorage.getItem(key)
      if (v == null) {
        continue
      }
      s.push({
        key: localStorage.key(i),
        value: JSON.parse(v),
      })
    }
    return s
  }
  public getItem(key: string) {
    let item = localStorage.getItem(key)
    if (item == null) {
      return null
    }
    let value = JSON.parse(item)
    return value
  }
  public store(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data))
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({ key: key, value: data })
  }
  public setItem(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data))
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({ key: key, value: data })
  }
  public clear(key: string) {
    localStorage.removeItem(key)
    // the local application doesn't seem to catch changes to localStorage...
    this.onSubject.next({ key: key, value: null })
  }

  private start(): void {
    // if (window.INITIALIZED) {
    //   window.addEventListener('storage', this.storageEventListener.bind(this))
    // }

  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea === localStorage) {
      let v
      try {
        v = JSON.parse(event.newValue ?? '')
      } catch (e) {
        v = event.newValue
      }
      this.onSubject.next({ key: event.key ?? '', value: v })
    }
  }

  private stop(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this))
    this.onSubject.complete()
  }
}
