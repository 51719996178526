// import { useHistory } from 'react-router-dom';
import './stories.scss';
import userSmartHistory from "../../metrica/metrica";
import { useAppDispatch, useAppSelector } from '../../hooks';
import { showStory } from '../../features/stories/storiesSlice';


export function HeaderStories() {
  let stories = useAppSelector((state) => state.stories.stories);
  const dispatch = useAppDispatch();
  return (
    <div className="header-stories-container">
      <div className="header-stories">
        {stories.map((story, index) => (
          <div key={index} className="story-item" onClick={() => { dispatch(showStory(story.id))}}>
            <div className="story-image-wrapper">
              <div className="story-border">
                <img src={story.image} alt={story.name} className="story-image" />
              </div>
            </div>
            <div className="story-name">{story.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}


