import './order-stages.scss';

export interface OrderStagesProps {
    currentStage: number;
}

export function OrderStages(props: OrderStagesProps) {
    let steps = [
        { name: 'Выбор неисправности' },
        { name: 'Ввод данных' },
        { name: 'Информация о телефоне' },
        { name: 'Завершить оформление' }
    ];
    let stepsBlock = steps.map((a,i) => (
        <li  key={i} className={i<=props.currentStage?"order-stage active":"order-stage"}>
            <span className="stage_number">{i+1}</span>
            <article className="stage_text">{a.name}</article>
        </li>
    ));
    return (<section className="order-stages">
        <ul className="order-stages_list">
            {stepsBlock}
        </ul>
    </section>)
}
