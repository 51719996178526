import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"

export interface OrderCommentState {
    text: string    
}

const initialState: OrderCommentState={
    text: ''
}
export const orderCommentSlice = createSlice({
    name: 'orderComment',
    initialState,
    reducers: {
        onKey: (state,action) => {
            if(state.text==""){
                state.text+=action.payload.toUpperCase();
            }
            else{
                state.text+=action.payload
            }
        },
        onBackspace: (state) => {
            state.text=state.text.slice(0,-1);
        },
        onEnter: (state) => {
            state.text=state.text + "\n";
        }
    },
    extraReducers: (builder) => {
        builder.addCase("CLEANUP", (state) => {
          state.text = ""
        })
      }
  })
  
  export const {onKey, onBackspace, onEnter} = orderCommentSlice.actions
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectOrderCommentState = (state: RootState) => state.orderComment
  export const selectOrderCommentText = (state: RootState) => state.orderComment.text

  export default orderCommentSlice.reducer