
import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { loadCategories, loadConfig, loadPhones, simProduct } from "../../network/actions";
import { RootState } from "../../store/store"
import { Category, PromoData } from "../../sdk/client/model/BoxSdk/UIModel/category";
import { useDebugValue } from "react";
import { categories } from "../product-list/category";


export enum CategoryLoadStatus {
  Loading,
  Loaded
}

function pageCategories(categories: Category[], pageIndex: number): Category[] {
  if (categories.length > 6) {
    let fromIndex = pageIndex * 5
    let toIndex = pageIndex * 5 + 5
    return categories.filter((a, i) => fromIndex <= i && i < toIndex)
  } else {
    return categories
  }
}
export interface HomeState {
  simProductId: number | null;
  pageIndex: number;
  isSaled: Boolean;

  isMaintenance: Boolean;
  supportPhone: string;
  boxId: number;
  is_free_order: boolean;
  promo: PromoData | null;
  all_order:number| null
	all_buy:number|null
  default_qr: string | null;
  categories: Category[];
  pageCategories: Category[];
  selectedCategory?: Category;
  categoryVersion: number;
  needReload: boolean,
  videoIndex: number,
  categoryLoadStatus: CategoryLoadStatus,
  howItWorksWrapperShow: boolean
  promo_index: number;
}

const initialState: HomeState = {
  categories: [],
  pageCategories: [],
  simProductId: null,
  supportPhone: '',
  boxId: 0,
  pageIndex: 0,
  videoIndex: 0,
  categoryVersion: 0,
  needReload: true,
  isSaled: false,
  default_qr: null,
  all_order: null,
	all_buy:null,
  promo_index: 0,
  is_free_order: false,
  isMaintenance: false,
  categoryLoadStatus: CategoryLoadStatus.Loading,
  howItWorksWrapperShow: false,
  promo: null,

}
let list = new Array<any>()
function preloadImages(array: Array<string>) {

  for (var i = 0; i < array.length; i++) {
    var img = new Image();
    img.onload = function () {
      var index = list.indexOf(this);
      if (index !== -1) {
        // remove image from the array once it's loaded
        // for memory consumption reasons
        array.splice(index, 1);
      }
    }
    list.push(img);
    img.src = array[i];
  }
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    onChangeHowItWorksWrapperShow: (state) => {
      state.howItWorksWrapperShow = !state.howItWorksWrapperShow;
    },
    onCloseHowItWork: (state) => {
      state.howItWorksWrapperShow = false
      state.promo_index += 1
    },
    onUpdateStats: (state, action) => {
      state.all_buy = action.payload.all_buy
      state.all_order= action.payload.all_order
    },
    needReload(state) {
      state.needReload = true;
      state.categoryVersion = state.categoryVersion + 1
    },
    selectCategory(state, action) {
      state.selectedCategory = action.payload
    },
    onNextPage(state) {
      let nextPage = state.pageIndex + 1
      state.pageIndex = nextPage % Math.ceil(state.categories.length / 5)
      state.pageCategories = pageCategories(state.categories, state.pageIndex)
    },

    changeVideoIndex(state) {
      state.videoIndex = state.videoIndex + 1;
    },
    startSale(state) {
      state.isSaled = true;
    }
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {
      state.pageIndex = 0
      
      state.pageCategories = pageCategories(state.categories, state.pageIndex)
    })
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(simProduct.fulfilled, (state, action) => {

      if (action.payload != null && action.payload.inStock) {
        state.simProductId = action.payload.productId
      } else {
        state.simProductId = null
      }
    });
    builder.addCase(loadCategories.fulfilled, (state, action) => {
      state.categories = action.payload.items
      state.pageIndex = 0
      state.isSaled = action.payload.is_sale
      console.log(`DEBUG salve ${state.isSaled}`)
      // preloadImages( action.payload.items.flatMap(a=>a.img))
      state.needReload = false;
      state.categoryLoadStatus = CategoryLoadStatus.Loaded;
      state.pageCategories = pageCategories(state.categories, state.pageIndex)
      // state.pageCategories = state.categories


    })
    builder.addCase(loadConfig.fulfilled, (state, action) => {
      state.isMaintenance = action.payload.is_maintenance
      // state.isVerifyPhoneNumber = action.payload.number_check
      state.supportPhone = action.payload.phone
      state.boxId = action.payload.box_id
      state.is_free_order = action.payload.is_free_order
      state.promo = action.payload.promo
      state.all_order = action.payload.stats?.all_order
      state.all_buy = action.payload.stats?.all_buy
      // preloadImages( action.payload.items.flatMap(a=>a.img))
    })


  },
})

export const {
  onChangeHowItWorksWrapperShow,
  needReload,
  changeVideoIndex,
  selectCategory,
  startSale,
  onNextPage,
  onCloseHowItWork,
  onUpdateStats
} = homeSlice.actions

// export const selectAllVideos = (state: RootState) => state.home.allVideos.filter(a=>a.id!=state.home.focusedVideo.id)
// export const selectFocusedVideo = (state: RootState) => state.home.focusedVideo
// export const selectVideoShow = (state: RootState) => state.home.videoShow
export const selectIsNeedReload = (state: RootState) => state.home.categories.length == 0 || state.home.needReload
export const selectCategoriyVersion = (state: RootState) => state.home.categoryVersion
export const selectPageCategories = (state: RootState) => state.home.pageCategories
export const selectIsNeedNextPage = (state: RootState) => state.home.categories.length > 6
export const selectSelectedCategory = (state: RootState) => state.home.selectedCategory
export const selectSimProductId = (state: RootState) => state.home.simProductId
export const selectVideoIndex = (state: RootState) => state.home.videoIndex
export const selectCategoriesLoadStatus = (state: RootState) => state.home.categoryLoadStatus
export const selectHowItWorksWrapperShow = (state: RootState) => state.home.howItWorksWrapperShow

export const selectSupportPhone = (state: RootState) => state.home.supportPhone
export const selectBoxId = (state: RootState) => state.home.boxId
export const selectIsFreeOrder = (state: RootState) => state.home.is_free_order
export const selectDefaultQr = (state: RootState) => state.home.default_qr
export const selectDefaultQrIndex = (state: RootState) => state.home.promo_index
export const selectDefaultQrUsedIndex = (state: RootState) => state.saledWindow.used_promo_index
export const selectBuyCount = (state: RootState) => state.home.all_buy
export const selectRepairCount = (state: RootState) =>  state.home.all_order
export const selectSelectPromoData = (state: RootState) => state.home.promo

export default homeSlice.reducer
export interface Stats {
  all_buy: number;
  all_order: number;
}