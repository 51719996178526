import { Keyboard } from "../keyboard/Keyboard";
import { OrderStages } from '../../components/order-stages/OrderStages';
import './confirm-phone.scss';
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    onBackspace,
    onChangeFocus,
    onKey,
    onEnter,
    selectConfirmPhoneState,
    selectShouldCheck,
    selectConfirmCodeValid,
    selectConfirmUserExists,
    VerifyType,
    selectedVerifyType,
    selectDeterminedVerifyType,
} from "./confirmPhoneSlice";

import { DownVideo } from "../../components/down-video/DownVideo";
import { CategoryLine } from "../../components/category-line/CategoryLine";
import useSmartHistory from "../../metrica/metrica";
import { selectIsBackToComment } from "../client-data/clientDataSlice";
import { selectShouldSend, selectIsVerifyPhoneNumber } from "./confirmPhoneSlice";
import { useEffect } from "react";
import { confirmCodeCheck, smsRequest } from "../../network/actions";
import { Numboard } from "../numboard/numboard";
import { debug } from "console";


export function ConfirmPhone() {

    const confirmPhone = useAppSelector(selectConfirmPhoneState);
    let navigate = useSmartHistory()
    const dispatch = useAppDispatch()

    const isPhoneValid = (phone: string) => {
        return phone.length == 16 || phone.length == 0
    }
    const confirmData = useAppSelector(selectConfirmPhoneState);
    let firstPhoneError = !isPhoneValid(confirmPhone.firstPhoneNumber)

    let isShouldSendVerfityRequest = useAppSelector(selectShouldSend);
    let isShouldCheckCode = useAppSelector(selectShouldCheck);
    let isConfirmCodeValid = useAppSelector(selectConfirmCodeValid);
    let isBackToComment = useAppSelector(selectIsBackToComment);
    let isUserExists = useAppSelector(selectConfirmUserExists);
    let isVerifyPhoneNumber = useAppSelector(selectIsVerifyPhoneNumber);
    let determinedVerifyType = useAppSelector(selectDeterminedVerifyType);
    let continueHandler = () => {
        if (!isVerifyPhoneNumber){
            navigate.push('/client-data');
        }else{
            navigate.push('/phone-data');
        }
       
    }
    console.log(`DEBUG: determinedVerifyType ${determinedVerifyType}`)
    let backHandler = () => {
        if (isBackToComment == true) {
            navigate.push('/order-comment')
        } else {
            navigate.push('/order-defects')
        };
    }
    useEffect(() => {
      
        if (isShouldSendVerfityRequest) {
            debugger
            dispatch(smsRequest({
                phone:confirmData.firstPhoneNumber,
                type:determinedVerifyType,
            }))
        }
        if (isShouldCheckCode) {
            dispatch(confirmCodeCheck({
                phone: confirmData.firstPhoneNumber,
                code: confirmData.confirmCode,
            }))
        }
        if (isConfirmCodeValid) {
            debugger
            if (isUserExists) {
                navigate.push('/phone-data');
            } else {
                navigate.push('/client-data');
            }
        }
    })
    // useEffect(() => {
    //     const interval = setTimeout(() => {
    //         dispatch(timeTick())
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, []);
    let isCanContinue = !firstPhoneError
        && (confirmData.isConfirmCodeValid || confirmData.verifyPhoneType==VerifyType.None)
    let isShowError = !confirmData.isConfirmCodeValid && confirmData.isConfirmCodeEntered
    let verificationType = confirmData.verifyPhoneType
    let isShowSended = confirmData.isConfirmCodeSended && !confirmData.isConfirmCodeEntered

    let selectedVerifyTypeValue =  confirmData.selectedVerifyType  
    return (<>
        <OrderStages currentStage={1} />

        <div className="above-keyboard-block above-buttons-block">
            <CategoryLine categoryText='Введите номер телефона:' />
            <section className="input-client ">
                <div className="inputs">


                    <div className="order-input_line">
                        <div className="order-input_wrapper" onClick={() => dispatch(onChangeFocus('firstPhoneNumber'))}>
                            <input value={confirmData.firstPhoneNumber}
                                type="text"
                                onChange={() => { }}
                                className={`order-input ${confirmData.focusInputKey == 'firstPhoneNumber' ? 'focused' : firstPhoneError ? 'error' : ''}`}
                                placeholder="Номер телефона" />
                        </div>
                    </div>

                    {verificationType == VerifyType.Optional ? (<div className="configm-mode-row">
                        <div className={"configm-mode-button " + (confirmData.selectedVerifyType == VerifyType.Call ? 'selected' : confirmData.selectedVerifyType == VerifyType.Sms ? 'disabled' : '')}
                            onClick={() => dispatch(selectedVerifyType(VerifyType.Call))}>
                            Звонок
                        </div>
                        <div className={"configm-mode-button " + (confirmData.selectedVerifyType == VerifyType.Sms ? 'selected' : confirmData.selectedVerifyType == VerifyType.Call ? 'disabled' : '')}
                            onClick={() => dispatch(selectedVerifyType(VerifyType.Sms))}>
                            СМС
                        </div>
                    </div>) : (<></>)}
                    {
                        isShowSended && (verificationType == VerifyType.Call || selectedVerifyTypeValue ==  VerifyType.Call) ? 
                        (<div className="order-input_line title info confirmed">
                            Введите последние 4 цифр <br />
                            +7-000 000 XX XX
                        </div>) : (<></>)
                    }
                    {
                        isShowSended &&( verificationType == VerifyType.Sms || selectedVerifyTypeValue ==  VerifyType.Sms) ? 
                        (<div className="order-input_line title info confirmed">
                            Введите код подтверждения из смс
                        </div>) : (<></>)
                    }
                    {
                        !isShowError && !isShowSended ? (<div className="order-input_line title placeholder">
                            Просто чтобы место занимало
                        </div>) : (<></>)
                    }
                    {isVerifyPhoneNumber ? (
                        <div className="order-input_line">
                            <div className="order-input_wrapper" onClick={() => dispatch(onChangeFocus('confirmCode'))}>
                                <input value={confirmData.confirmCode} type="text"
                                    className={`order-input ${confirmData.focusInputKey == 'confirmCode' ? 'focused' : ''}`}
                                    onChange={() => { }}
                                    placeholder={confirmData.focusInputKey == "confirmCode" ? "" : "Код подтверждения"} />
                            </div>
                        </div>
                    ) : (<></>)}

{
                    isShowError ? (<div className="order-input_line title error">
                           Код не правильный
                        </div>) : (<></>)
                    }

                </div>
                <div className="input-keyboard-column">

                    <div className="numboard_wrapper">
                        <Numboard
                            onBackspace={() => { dispatch(onBackspace()); }}
                            onKey={(a) => { dispatch(onKey(a)); }}
                        />
                    </div>

                </div>

            </section>

        </div>
        <div className="defects_buttons">
            <button onClick={() => { backHandler() }} className="main-button black back">Назад</button>
            <button onClick={() => {
                if (isCanContinue) {
                    continueHandler()
                }
            }} className={`main-button yellow next ${(isCanContinue ? '' : 'disabled')}`} disabled={!isCanContinue}>Далее</button>
        </div>
        <DownVideo />
    </>)
}
