import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectOrderNumber } from '../order-end/orderEndSlice';
import { selectSuggestions, selectWorkshopPhone } from '../product-page/productPageSlice';
import './finish-buy.scss';

import { cleanSpbQr } from '../sbp-window/sbpWindowSlice';
import { TopLine } from '../../components/top-line/TopLine';
import useSmartHistory from '../../metrica/metrica';

export function FinishBuy() {

    let navigate = useSmartHistory();
    const suggestions = useAppSelector(selectSuggestions);
    const dispatch = useAppDispatch();
    return (<>
        <TopLine onClick={() => {
            navigate.push('/')
        }} sectionStyle='left' sectionIconName='back-button.svg' sectionHeading='На главную' sectionType='icon' />

        <div className="order-result-wrapper">
            <div className="order-result">


                <div className="getproduct above-buttons-block">
                    <div className="column">
                        <video autoPlay loop muted height="" className="finish-video qr-video">
                            <source className="footer-video_content" src="/assets/videos/video_getproduct.mp4" type='video/mp4' />
                        </video>


                    </div>
                    <div className="column arrow-container">
                        <div className="headers-container">

                            <span className="order-result-number">Заберите товар в зоне выдачи</span>
                        </div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                        <div className="chevron"></div>
                    </div>
                </div>
                
            </div>
        </div>
        {/* <TopLine onClick={() => { }} sectionStyle='left' sectionIconName='gift.svg' sectionHeading='Также можете купить:' sectionType='icon' />

        <section className="item-list">
            <div className="container">
                {suggestions.map((a, i) =>
                    <div key={i} className="item-card" onClick={() => {
                        dispatch(cleanSpbQr())
                        navigate.push(`/product/${a.id}/data`)
                    }}>
                        <span className="item-card_title">{a.name}</span>
                        <img src={a.main_img} alt="" className="item-card_pic" />
                        <div className="item-card_price">{a.price}р.</div>
                    </div>
                )}

            </div>
        </section> */}
    </>)
}
