import { useHistory } from "react-router";
import { CategoryLine } from "../../components/category-line/CategoryLine";
import { OrderStages } from "../../components/order-stages/OrderStages";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { selectOrderDefects, selectIsNeedComment, toggleDefect, selectIsCanContinue } from "./orderDefectsSlice";
import './order-defects.scss';
import { Checkbox } from "../../components/checkbox/Checkbox";
import { DownVideo } from "../../components/down-video/DownVideo";
import { TimerWindow } from "../timer-window/timerWindow";
import useSmartHistory from "../../metrica/metrica";
import { selectConfirmUserExists, selectIsPhoneConfirmed } from "../confirm-phone/confirmPhoneSlice";

export function OrderDefects() {
    const defects = useAppSelector(selectOrderDefects);
    const isNeedComment = useAppSelector(selectIsNeedComment);
    const isCanContinue = useAppSelector(selectIsCanContinue);
    let isUserExists = useAppSelector(selectConfirmUserExists);
    let isPhoneConfirmed = useAppSelector(selectIsPhoneConfirmed);
    const dispatch = useAppDispatch()

    let navigate = useSmartHistory();
    return (
        <>
            <OrderStages currentStage={0} />

            <section className="defects above-buttons-block">
                <CategoryLine categoryText='Выбор неисправности' />
                <div >
                    <div className="defects_list">
                        {defects.defectsList.map(a => <div key={a.id} className="defect">
                            <Checkbox onChange={() => { dispatch(toggleDefect(a.id)) }} checked={a.selected} heading={a.name} color={a.color} />
                        </div>)}

                    </div>
                </div>

            </section>
            <div className="defects_buttons">
                <button onClick={() => { navigate.push('/repair-options') }} className="main-button black back">Назад</button>
                <button onClick={() => {
                    if (isCanContinue) {
                        if (isNeedComment == true) {
                            navigate.push('/order-comment')
                        } else {
                            if (isPhoneConfirmed) {
                                if (isUserExists) {
                                    navigate.push('/phone-data');
                                } else {
                                    navigate.push('/client-data');
                                }
                            } else {
                                navigate.push('/confirm-phone');
                            }
                        }
                    }

                }} className={`main-button yellow next ${(isCanContinue ? '' : 'disabled')}`} disabled={!isCanContinue}>Далее</button>
            </div>
            <DownVideo />
        </>

    )
}
