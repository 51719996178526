
import './wait-receipt.scss';

export interface WaitReceiptProps {

}

export function WaitReceipt(props: WaitReceiptProps) {
    return (
        <section className="wait-receipt-wrapper">
            <div className="darked-background" ></div>

            <div className="wait-dialog">
                <div className="wait-dialog-title">
                    Оплата успешна
                </div>
                <div className="wait-dialog-content">
                    <img src="/assets/img/wait-receipt.png" alt="" />
                </div>
                <div className="wait-dialog-footer">
                    Дождитесь чека
                </div>
            </div>
        </section>
    )
}