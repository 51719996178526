
let setted = false;
export  function setQrScaner(cb:any) {
    if(setted){
        return;
    }
    setted=true;
    var code = ''
    var reading = false;
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
   
    if (params.qr!=null&&params.qr!=""){
        console.log("Set qr timer from query param: ",params.qr)
        setTimeout(()=>{
            console.log("Qr from query: ",params.qr)
            cb(params.qr)
            
        },5000)
    }else{
        console.log("Set qr timer faled")
    }
    
    document.addEventListener("keypress", function (e) {
        let now = new Date().getTime()
        console.log(`keypres: ${code} ${e.key}`)
      
        if ( e.key=='Enter') {
            console.log(`enter pressed: ${code} ${code.length}`)
            if (code.length > 9) {
                cb(code);
                /// code ready to use                
                code = "";
            }
        } else {
            code += e.key; //while this is not an 'enter' it stores the every key            
        }

        //run a timeout of 200ms at the first read and clear everything
        if (!reading) {
            reading = true;
            setTimeout(() => {
                code = "";
                reading = false;
                console.log(`qrtimeout`)
            }, 1000);  //200 works fine for me but you can adjust it
        }
    })

}
