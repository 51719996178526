import './down-video.scss';

export function DownVideo() {
    return (
       <div>

{/* <section className="footer-video_wrapper">
            <video autoPlay loop muted width="400" height="300" className="footer-video">
                <source className="footer-video_content" src="/assets/videos/footer-video.mp4" type='video/mp4'/>
            </video>
        </section> */}
       </div>)
}
