import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../store/store"

export interface KeyboardState {
  currentLang: 'en'|'ru';
  shiftOn: boolean;
}

const initialState: KeyboardState={
    currentLang: 'ru',
    shiftOn: true
}

export const keyboardSlice = createSlice({
    name: 'keyboard',
    initialState,
    reducers: {
        onChangeLang: (state) => {
          state.currentLang=state.currentLang=='ru'?'en':'ru';
        },
        onChangeShift: (state) =>{
          state.shiftOn=!state.shiftOn;
        }
    }
  })
  
  export const {onChangeLang,onChangeShift} = keyboardSlice.actions
  
  export const selectKeyboardState = (state: RootState) => state.keyboard;
  export const selectCurrentLang = (state: RootState) => state.keyboard.currentLang;
  export const selectShiftOn = (state: RootState) => state.keyboard.shiftOn;
  
  export default keyboardSlice.reducer
