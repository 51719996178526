
import './loading.scss';
export function Loading(){
    return (<section >
    <div className="loading">
        
        <img className="loading_img" src="assets/img/loading.gif" alt=""></img>
        <div className="loading_heading">ЗАГРУЗКА</div>
    </div>
</section>)
}
