import { State } from '../../../../client/model/PayQrSber/Enum/state.enum';
export class OrderCreateQrRs {
	rq_uid!: string;
	rq_tm!: Date;
	order_number!: string;
	order_form_url!: string;
	error_code!: string;
	error_description!: string;
	order_id!: string;
	order_state!: State;
}

export interface SendSmsResponse{
	success:boolean
	message:string
}
export enum VerifyType{
	Sms,
	Phone
	
}
export interface CheckConfirmCode{
	phone:string
	code:string
}
export interface ConfirmResponse{
	success:boolean
	user:boolean
	user_id:number
	phone:string
}