import { HttpClient } from "sdk-remlite"

export class HttpClientImpl implements HttpClient {
  device_token?: string
  user_token?: string
  constructor(private host: string) {}
  setDeviceToken(token: string) {
    this.device_token = token
  }
  setAccountToken(token: string) {
    this.user_token = token
  }
  private defHeaders(): Headers {
    let h = new Headers()
    h.set('Accept', 'application/json')
    h.set('Content-Type', 'application/json')
    let token = this.user_token ?? this.device_token
    if (token != null) {
      h.set('Authorization', `bearer ${token}`)
    }
    return h
  }
  public get<T>(url: string, headers?: Headers): Promise<T> {
    let h: Headers = headers ?? this.defHeaders()
    let request = {
      method: 'GET',
      headers: h,
    } as Request
    return fetch(`${this.host}${url}`, request)
    .then((a: any) => {
      let data =a.json()
      
      return data
    })
  }
  public put<T>(url: string, headers?: Headers, params?: any): Promise<T> {
    let h: Headers = headers ?? this.defHeaders()
    let request = {
      method: 'PUT',
      headers: h,
      body: params,
    }
    return fetch(`${this.host}${url}`, request)
    .then((a: any) => {
      let data =a.json()
      
      return data
    })
  }
  public post<T>(path: string, headers?: Headers, params?: any): Promise<T> {
    let h: Headers = headers ?? this.defHeaders()
    let body = params == null ? '' : JSON.stringify(params)
    let request = {
      method: 'POST',
      headers: h,
      body: body,
    }
    let url = `${this.host}${path}`
    return fetch(url, request).then((a: any) => a.json())
  }
  public delete<T>(url: string, headers?: Headers): Promise<T> {
    let h: Headers = headers ?? this.defHeaders()
    let request = {
      method: 'DELETE',
      headers: h,
    }
    return fetch(`${this.host}${url}`, request).then((a: any) => a.json())
  }
}
