export enum State {
	PAID = 0,
	CREATED = 1,
	REVERSED = 2,
	REFUNDED = 3,
	REVOKED = 4,
	DECLINED = 5,
	EXPIRED = 6,
	AUTHORIZED = 7,
	CONFIRMED = 8,
	ON_PAYMENT = 9
}
