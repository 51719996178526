import { useDispatch } from 'react-redux';
import { TOUCH_TYPE } from '../../config/config';
import './prompt.scss';


export interface PromptProps {
    promptHeading: string;
    promptDescription: string;
    promptShow: boolean;
    promptOpener: () => void;
}

export function Prompt(props: PromptProps) {
    const dispatch = useDispatch();
    const onPromptOpen = (e: any) => {
        console.log('Touched',e);
        if(e.type != TOUCH_TYPE){
            return
        }
        e.stopPropagation()
        props.promptOpener()
    }
    return (
    <section className={`prompt_wrapper ${props.promptShow == true ? 'show' : 'hide'}`} onClick={onPromptOpen} onTouchStart={onPromptOpen}>
        <div className={"darked-background"}></div>
        <div className="prompt_content">
            <span className="prompt_heading">
                <i className="prompt_heading_line">
                    {props.promptHeading}
                    <span className="prompt_icon"></span>
                </i>
            </span>
            <p className="prompt_description">{props.promptDescription}</p>
        </div>
    </section>)
}