
import "./emul.scss"

export function EmulBox() {
    return (
        <div className="emulator">
            <div className="screen">
        <div className="iframe_border"></div>
                <iframe className="iframe"  src="https://remlite.boomeringo.ru/" ></iframe>
            </div>
           

           <div className="box">
                <div className="box-left">
                <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                </div>
                <div className="box-center"></div>
                <div className="box-right">
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>
                    <div className="box-row">
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                        <div className="box-cell"></div>
                    </div>

                </div>
           </div>
        </div>

    )
}