import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { CategoryLine } from '../../components/category-line/CategoryLine';
import { OrderStages } from '../../components/order-stages/OrderStages';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectOrderNumber } from '../order-end/orderEndSlice';
import { selectSuggestions, selectWorkshopPhone } from '../product-page/productPageSlice';
import './finish.scss';
import { TopLine } from '../../components/top-line/TopLine';
import { cleanSpbQr } from '../sbp-window/sbpWindowSlice';
import useSmartHistory from '../../metrica/metrica';

export function Finish() {
    let orderNumber = useSelector(selectOrderNumber)
    let navigate = useSmartHistory();
    const suggestions = useAppSelector(selectSuggestions);
    const workshopPhone = useAppSelector(selectWorkshopPhone);
    const dispatch = useAppDispatch();
    return (<>
   <TopLine onClick={()=>{
    navigate.push('/')
   }} sectionStyle='left' sectionIconName='back-button.svg'   sectionHeading='На главную' sectionType='icon'/>

    <div className="order-result-wrapper">
        <div className="order-result">
            <span className="order-result-heading">Спасибо, что выбрали нас!</span>
            <span className="order-result-number">Номер заказа: <b className='order-number'>{orderNumber}</b></span>
            {/* <p className="order-result-description">Nелефону:<br/>{workshopPhone}</p> */}
            {/* <p className="order-result-description">В нашем терминале вы также можете
приобрести товары по выгодным ценам</p> */}
        </div>
    </div>
    {/* <TopLine onClick={()=>{}} sectionStyle='left' sectionIconName='gift.svg'   sectionHeading='Купи со скидкой 10% прямо сейчас' sectionType='icon'/> */}
    <TopLine onClick={()=>{}} sectionStyle='left' sectionIconName='gift.svg'   sectionHeading='Также можете купить:' sectionType='icon'/>

    <section className="item-list">
            <div className="container">
            {suggestions.map((a, i) =>
                    <div key={i} className="item-card" onClick={() => { 
                        dispatch(cleanSpbQr())
                        navigate.push(`/product/${a.id}/data`) }}>
                        <span className="item-card_title">{a.name}</span>
                        <img src={a.main_img} alt="" className="item-card_pic" />
                        <div className="item-card_price">{a.price}р.</div>
                    </div>
                )}
                
            </div>
        </section>
    {/* <section className="finish-block">
        <img src="assets/img/end-pic.png" alt="" className="footer-phone_icon"/>
    </section> */}
    </>)
}
