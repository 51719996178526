import { useHistory } from "react-router";
import { useAppDispatch } from "../../hooks";
import { onQrScaned } from "../../network/actions";
import { setQrScaner } from "../../tools";


export interface QrScanerProps{
    children: JSX.Element;
}
export function QrScaner(props: QrScanerProps) {
setQrScaner((qr: string)=>{
    onQrScaned(qr)
})
return (
    <>
    {props.children}
    </>
)
}
