import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store/store";
export interface StoriesVideoItem {
  path: string
  duration: number
}
export interface StoriesItem {
  id: number;
  name: string;
  image: string;
  videos: StoriesVideoItem[];
}

export interface StoriesState {
  stories: StoriesItem[];
  currentStoryId: number | null;
  currentStory: StoriesItem | null;
  currentVideoIndex: number;
  videoProgress: number;
}

const initialState: StoriesState = {
  stories: [
    {
      id: 0,
      name: "Работа терминала",
      image: "/assets/img/stories/work.jpg",
      videos: [
        { path: "/assets/videos/stories/work1.mp4", duration: 46.73 },
        { path: "/assets/videos/stories/work2.mp4", duration: 25.63 },
        { path: "/assets/videos/stories/work3.mp4", duration: 38.13 }
      ],
    },
    {
      id: 1,
      name: "О нас",
      image: "/assets/img/stories/about.jpg",
      videos: [
        { path: "/assets/videos/stories/about1.mp4", duration: 29.53 },
        { path: "/assets/videos/stories/about2.mp4", duration: 29.05 }
      ],
    },
    {
      id: 2,
      name: "Мастерская",
      image: "/assets/img/stories/workshop.jpg",
      videos: [
        { path: "/assets/videos/stories/workshop1.mp4", duration: 40.17 }
      ],
    },
    {
      id: 3,
      name: "Отзывы",
      image: "/assets/img/stories/reviews.jpg",
      videos: [
        { path: "/assets/videos/stories/review.mp4", duration: 57.67 }
      ],
    },
    {
      id: 4,
      name: "Локации",
      image: "/assets/img/stories/location.jpg",
      videos: [
        { path: "/assets/videos/stories/location.mp4", duration: 23.08 }
      ],
    },
  ],
  currentStoryId: null,
  currentStory: null,
  currentVideoIndex: 0,
  videoProgress: 0,
};

export const storiesState = createSlice({
  name: "StoriesState",
  initialState,
  reducers: {
    showStory: (state, action: PayloadAction<number>) => {
      state.currentStoryId = action.payload;
      state.currentStory = state.stories.find(story => story.id === action.payload) || null;
      state.videoProgress = 0; // Начинаем с нулевого прогресса
      state.currentVideoIndex = 0;
    },
    onStoreisProgress(state, action: PayloadAction<number>) {
    },
    onCloseStory: (state) => {
      state.currentStoryId = null;
      state.currentStory = null;
      state.videoProgress = 0;
      state.currentVideoIndex = 0;
    },
    updateVideoProgress: (state, action: PayloadAction<number>) => {
      state.videoProgress = action.payload;
    },
    incrementVideoIndex: (state) => {

      if (state.currentStory && state.currentStory.videos) {
        if (state.currentVideoIndex < state.currentStory.videos.length - 1) {
          state.currentVideoIndex += 1;
          state.videoProgress = 0; // Сброс прогресса для нового видео
        } else {
          // Если это последнее видео в истории, закрываем историю
          state.currentStoryId = null;
          state.currentStory = null;
          state.currentVideoIndex = 0;
          state.videoProgress = 0;
        }
      } else {
        state.currentStoryId = null;
        state.currentStory = null;
        state.currentVideoIndex = 0;
        state.videoProgress = 0;
      }
    },
    onSwipeVideo: (state, action: PayloadAction<number>) => {
      const nextStoryId = state.currentStoryId !== null ? state.currentStoryId + action.payload : 0;
      if (nextStoryId >= 0 && nextStoryId < state.stories.length) {
        state.currentStoryId = nextStoryId;
        state.currentStory = state.stories[nextStoryId] || null;
        state.videoProgress = 0;
        state.currentVideoIndex = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase("CLEANUP", (state) => {
      state.currentStoryId = null;
      state.currentStory = null;
      state.videoProgress = 0;
      state.currentVideoIndex = 0;
    });
  },
});

export const { showStory, onCloseStory, updateVideoProgress, onSwipeVideo, onStoreisProgress, incrementVideoIndex } = storiesState.actions;

// Selectors
export const selectCurrentStory = (state: RootState) => state.stories.currentStory;
export const selectVideoProgress = (state: RootState) => state.stories.videoProgress;
export const selectStories = (state: RootState) => state.stories.stories;
export const selectCurrentVideoIndex = (state: RootState) => state.stories.currentVideoIndex;
export const selectCurrentVideo = (state: RootState) => {
  const currentStory = state.stories.currentStory;
  if (currentStory && currentStory.videos) {
    let path = currentStory.videos[state.stories.currentVideoIndex].path
    console.log(`selectCurrentVideo ${path}`)
    return path
  }

  return null
};

export const isLastVideo = (state: RootState) => {
  const currentStory = state.stories.currentStory;
  if (currentStory && currentStory.videos) {
    return currentStory.videos.length - 1 == state.stories.currentVideoIndex
  }
  return false
}

export default storiesState.reducer;