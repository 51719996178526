
import { RootState } from "../../store/store"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { createOrder } from "../../network/actions";
import { FullLiteOrderModel, OrderLiteCoreModel } from "../../sdk/client/model";
import { OrderService } from "../../sdk";
import { HttpClientImpl } from "../../sdk/share/rest/http.client.impl";

//import client, { OrderBaseModel, OrderFullModel } from "../../network/client/client"
export enum RepairMode{
  Cheper=1,
  Faster =2,
  Warranty =3,
  Free =4,
}
export interface OrderEndState {
  openDate:number|null;
  licenseAgree:boolean;
  repairMode:RepairMode|null;
  orderNumber:string|null
  workshopPhone:string|null
}

const initialState: OrderEndState = {
  openDate: null,
  licenseAgree: false,
  orderNumber:null,
  repairMode:null,
  workshopPhone:null,
}
export const orderEndSlice = createSlice({
  name: 'orderEnd',
  initialState,
  reducers: {
    openDate :(state)=>{
      state.openDate = new Date().getTime()
    },
    onChangeAgree:(state)=>{
      state.licenseAgree = !state.licenseAgree;
    },
    createOrderResult:(state,action)=>{
      state.orderNumber = action.payload.order_number
      state.workshopPhone = action.payload.workshop_phone 
    },
    repairMode:(state,action)=>{
      state.repairMode = action.payload
    }
  },
  extraReducers :(builder) => {
    builder.addCase("CLEANUP", (state,action:any) => {
      state.repairMode = action.mode
      state.licenseAgree =false
    })
  }
})

export const {openDate, onChangeAgree, createOrderResult,repairMode} = orderEndSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectOrderNumber = (state: RootState) => state.orderEnd.orderNumber
export const selectOrderEndState = (state: RootState) => state.orderEnd
export const selectOrderModel = (state: RootState) => {
  const defects = state.orderDefects.defectsList;
  let defectText = "";
  for (let i = 0; i < defects.length; i++) {
    let item = defects[i];
    if (item.selected) {
      if (defectText.length == 0) {
        defectText = item.name
      } else {
        defectText += ',' + item.name
      }
    }
  }
  let record_id = state.touch.id;
  
  const order = <FullLiteOrderModel>{
    order: {
      
      mode: state.orderEnd.repairMode,
      comment: state.orderComment.text,
      record_id: record_id,

    },
    user: {
      phone_number: state.confirmPhone.firstPhoneNumber,
      first_name: state.clientData.firstName,
      last_name: state.clientData.lastName,
      additional_phone_number: state.clientData.secondPhoneNumber,
      prefer_connect:state.clientData.selectedPrefer
    },
    malfunction: {
      malfunction: defectText
    },
    phone: {
      phone_manufacturer: state.phoneData.selectedBrand?.name,
      phone_model: state.phoneData.selectedModel?.name,
      phone_sn: state.phoneData.IMEI,
    }
  } ;
  return order;
}
export const selectOpenDate =(state: RootState) => state.orderEnd.openDate;
export const selectLicenseAgree =(state: RootState) => state.orderEnd.licenseAgree;
export const selectRepairMode =(state: RootState) => state.orderEnd.repairMode;
export default orderEndSlice.reducer
